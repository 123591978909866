<template>
    <div class="platon-dialog position-relative">
        <div class="pmodal-title d-flex align-items-center modal-form-header" :class="headerClass">
            <div class="flex-grow-1">
                <slot name="title">Modal</slot>
            </div>

            <div v-if="canCancel" @click="$parent.$emit('close')" class="cursor-pointer modal-close">
                <i class="fa fa-times-circle"></i>
            </div>
        </div>

        <div class="pmodal-body" :class="slotClass">
            <slot></slot>
        </div>

        <div v-if="localHasFooter && $slots.footer" :class="footerSlotClass" class="border-top">
            <slot name="footer"></slot>
        </div>

        <div v-if="footerActions" class="d-flex footer-actions form-modal-footer">
            <div
                :style="{ width: `${100 / footerActions.length}%` }"
                v-for="action in footerActions"
                class="text-center footer-action"
                :class="{ disabled: action.disabled }"
                @click="handleAction(action)"
            >
                <div v-show="!action.isLoading">
                    <i v-if="action.icon" :class="action.icon" />
                    <span>{{ action.text }}</span>
                </div>
                <div
                    role="status"
                    class="spinner-border spinner-border-sm text-primary ml-2"
                    v-show="action.isLoading"
                />
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "Modal",

    props: {
        slotClass: {
            default: "p-3 flex-grow-1"
        },

        headerClass: {},

        /** @type {ModalAction[]} */
        footerActions: {
            type: Array
        },

        footerSlotClass: {
            default: "p-3"
        },

        hasFooter: {
            default: true
        },

        canCancel: {
            default: true
        }
    },

    data() {
        return {
            localHasFooter: this.hasFooter
        }
    },

    methods: {
        /**
         * @param {ModalAction} action
         */
        async handleAction(action) {
            if (action.disabled) return

            let preventClosing = typeof action.handler === "function" ? await action.handler(this.hideModal) : false

            if (preventClosing !== true) {
                this.hideModal()
            }
        },

        hideModal() {
            this.$parent.$emit("close")
        }
    },

    watch: {
        hasFooter(val) {
            this.localHasFooter = val
        }
    }
}
</script>

<style scoped lang="scss">
@import "../../assets/styles/vars";

.pmodal-body {
    max-height: 60vh;
    overflow: auto;

    &::-webkit-scrollbar {
        width: 0 !important;
        height: 0 !important;
    }
}

.footer-actions {
    border-top: 1px solid $border;
}

.footer-action {
    height: 40px;
    align-items: center;
    justify-content: center;
    display: flex;

    user-select: none;
    cursor: pointer;

    &.disabled {
        color: lighten($text-gray, 5%);
        cursor: unset;
    }

    & > i {
        margin-right: 0.5rem;
    }

    &:not(.disabled):hover {
        background: #0000000c;
    }
    &:first-child {
        border-right: 1px solid $border;
    }
    &:last-child {
        border-left: 1px solid $border;
    }

    // It will appear when the given loading prop is false
    & .footer-actions-semantic {
        width: 100%;
        height: 100%;
    }
}
</style>
