import Axios from "axios"
import { localeInfo } from "@Platon/core/translations"
import { decodeToBase64, encodeToBase64 } from "./HttpHelpers"

window.Axios = Axios

/**
 * @param {AxiosRequestConfig|{}} options
 */
export default function (options) {
	let instance = Axios.create(options)

	instance.interceptors.response.use(
		(config) => {
			if (config.headers["content-type"] === "application/json+base64") {
				config.data = decodeToBase64(config.data)
			}

			return config
		},
		(error) => {
			if (error.config.headers["Content-Type"] === "application/json+base64") {
				error.response.data = decodeToBase64(error.response.data)
			}
			return Promise.reject(error)
		}
	)

	instance.interceptors.request.use((config) => {
		let token = localStorage.getItem("auth_token")
		const isNeedToAppendToken = typeof config.useAuthorization === "undefined" ? true : config.useAuthorization
		config.headers["Accept-Language"] = localeInfo.locale
		config.headers["ngrok-skip-browser-warning"] = "1" // skip browser warning

		if (config.method !== "get") {
			const transferMethod = Vue.prototype.$projectInfo && Vue.prototype.$projectInfo.transfer_method
			const isMultipartRequest = config.data instanceof FormData

			if (!isMultipartRequest && transferMethod) {
				config.data = encodeToBase64(config.data)
				config.headers["Content-Type"] = `application/json+${transferMethod}`
			}

			if (transferMethod) {
				config.headers["Accept"] = `application/json+${transferMethod}`
			}
		}

		const isPublicUrl = (config.baseURL + (config.url || "")).includes("public/")
		if (token && isNeedToAppendToken && !isPublicUrl) {
			config.headers["Authorization"] = `Bearer ${token}`
		}

		return config
	})

	return instance
}
