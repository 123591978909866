<template>
    <object class="pdfViewer" :data="url" type="application/pdf" width="100%" height="100%">
        <embed :src="url" type="application/pdf" />
    </object>
</template>

<script>
export default {
    name: "PdfPreview",

    props: {
        url: {
            required: true,
            type: String
        }
    }
}
</script>

<style>
.pdfViewer {
    min-height: 70vh;
}
</style>
