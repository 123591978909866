export const providers = {
	default: {
		divider: true,
		title: `<i class="fas fa-user mr-1"></i> Логин ва парол`,
		component: () => import("./LoginPasswordProvider.vue")
	},
	eimzo: {
		title: `<i class='fas fa-key mr-1'></i> ЭРИ`,
		component: () => import("./EImzoProvider.vue")
	},
	google: {
		title: `<i class='fas fa-key mr-1'></i> google`,
		component: () => import("./GoogleProvider.vue")
	},
	facebook: {
		title: `<i class='fas fa-key mr-1'></i> facebook`,
		component: () => import("./FacebookProvider.vue")
	},
	one_id: {
		title: `<i class='fas fa-key mr-1'></i> oneID`,
		component: () => import("./OneIdProvider.vue")
	},
	keycloak: {
		title: `Keycloak`,
		component: () => import("./KeycloakProvider.vue")
	},
	linkedin: {
		title: `Linkedin`,
		component: () => import("./LinkedinProvider.vue")
	},
	ssv: {
		title: `SSV`,
		component: () => import("./SsvProvider.vue")
	}
}
