import Btn from "@Platon/components/extended/Btn.vue"
import PlatonModal from "@Platon/components/extended/Modal.vue"
import PlatonMenu from "@Platon/components/menu/PlatonMenu.vue"
import PTable from "@Platon/components/table/PTable.vue"
import PlatonFormElement from "@Platon/components/form/PlatonFormElement.vue"
import KeyValueControl from "@Platon/components/form/controls/KeyValueControl.vue"
import Layout from "@Platon/components/layout/Layout.vue"
import Topbar from "@Platon/components/layout/Topbar.vue"
import LeftMenu from "@Platon/components/layout/LeftMenu.vue"
import PlatonLink from "@Platon/components/extended/PlatonLink.vue"
import BlankComponent from "@Platon/components/misc/BlankComponent.vue"
import PlatonNotFoundPage from "@Platon/components/pages/PlatonNotFoundPage.vue"
import NotificationContainer from "@Platon/components/extended/NotificationContainer"
import Router from "vue-router"

import { API_ENDPOINT, APP_NAME, APP_VERSION, CONSTANTS, COPYRIGHT, FRONTEND_URL } from "@Platon/const"
import { $language, $parametrizedLanguage } from "@Platon/core/translations"
import { $http, getUser, setPlatonApiClient } from "@Platon/core/core"
import { platonFileUrl, trimSlash, downloadBlob } from "@Platon/core/helpers"
import pathJoin from "@Platon/components/file-manager/pathJoin"
import moment from "moment"
import "./core/globalHelpers"
import HomePage from "@Platon/views/HomePage.vue"
import CreateHttpClient from "@Platon/core/http/CreateHttpClient"
import PlatonVueInstance from "@Platon/mixins/PlatonVueInstance"
import PlatonInputDataList from "@Platon/components/extended/PlatonInputDataList.vue"
import ImageCropperMixin from "@Platon/mixins/ImageCropperMixin"

import PlatonPluginCore from "@Platon/core/plugins/PlatonPluginCore"
import PlatonCorePlugins from "@Platon/platon/plugins/index.js"
import PluginEvents from "@Platon/core/plugins/PluginEvents"
import VueRuntimeScripts from "@Platon/VueRuntimeScripts"
import LoginPage from "@Platon/views/LoginPage.vue"
import LoginPasswordProviderMixin from "@Platon/components/login/LoginPasswordProviderMixin"
import LoginMixin from "@Platon/components/login/LoginMixin"
import KeycloakProviderMixin from "@Platon/components/login/KeycloakProviderMixin"
import EimzoProviderMixin from "@Platon/components/login/EimzoProviderMixin"
import DynamicPageRenderer from "@Platon/components/pages/DynamicPageRenderer"
import PlatonDatePicker from "@Platon/components/form/PlatonDatePicker"
import { pickUserOrganization } from "@Platon/mixins/pickUserOrganization"
import ProfilePage from "@Platon/views/profile/ProfileView.vue"

const PlatonTableView = () => import("./views/public/PlatonTableView.vue")
const PlatonFormView = () => import("./views/public/PlatonFormView.vue")
const PlatonPageView = () => import("./views/public/PlatonPageView.vue")
const FileManagerPage = () => import("./components/file-manager/FileManager.vue")
const ResizablePage = () => import("./components/pages/ResizablePage.vue")

const PlatonGrid = () => import("@Platon/components/builder/PlatonGrid")
const MultiTable = () => import("./components/table/MultiTable.vue")
const MultiTab = () => import("./components/table/MultiTab.vue")
const PlatonTable = () => import("./components/table/PlatonTable.vue")
const InstructionsPage = () => import("./components/instructions/InstructionsPage.vue")
const IconPickerDialog = () => import("./components/misc/IconPickerDialog.vue")
const PlatonChart = () => import("./components/chart/PlatonChart.vue")
const PlatonMapChart = () => import("./components/chart/PlatonMapChart.vue")
const MapWidget = () => import("./components/widgets/InfoMap.vue")
const MapPolygon = () => import("./components/form/controls/MapPolygon.vue")
const UploadControl = () => import("./components/form/controls/UploadControl.vue")
const ImageGallery = () => import("./components/misc/ImageGallery.vue")
const MapMarkerPicker = () => import("./components/map/MapMarkerPicker.vue")
const MapViewer = () => import("./components/map/MapViewer.vue")
const TinyMCE = () => import("./components/form/controls/TinyMCE.vue")

/**
 * @param Vue
 */
export function registerComponents(Vue) {
	Vue.component("PlatonGrid", PlatonGrid)

	Vue.component("PlatonTablePage", PlatonTableView)
	Vue.component("PlatonForm", PlatonFormView)

	Vue.component("PlatonBtn", Btn)
	Vue.component("PModal", PlatonModal)
	Vue.component("PlatonMenu", PlatonMenu)
	Vue.component("PlatonTable", PlatonTable)
	Vue.component("MultiTable", MultiTable)
	Vue.component("MultiTab", MultiTab)
	Vue.component("PTable", PTable)
	Vue.component("PlatonFormElement", PlatonFormElement)
	Vue.component("KeyValue", KeyValueControl)
	Vue.component("PlatonLayout", Layout)
	Vue.component("PlatonTopbar", Topbar)
	Vue.component("PlatonLeftMenu", LeftMenu)
	Vue.component("PlatonChart", PlatonChart)
	Vue.component("PlatonMapChart", PlatonMapChart)
	Vue.component("MapPolygon", MapPolygon)
	Vue.component("MapWidget", MapWidget)
	Vue.component("MapViewer", MapViewer)
	Vue.component("ImageGallery", ImageGallery)
	Vue.component("MapMarkerPicker", MapMarkerPicker)
	Vue.component("PlatonInputDataList", PlatonInputDataList)
	Vue.component("PInputDataList", PlatonInputDataList)
	Vue.component("PNotificationContainer", NotificationContainer)
	Vue.component("TinyMce", TinyMCE)
	Vue.component("date-range-picker", PlatonDatePicker)

	Vue.component("PlatonLink", PlatonLink)
	Vue.component("UploadControl", UploadControl)
	Vue.component("IconPickerDialog", IconPickerDialog)
	Vue.component("DynamicPageRenderer", DynamicPageRenderer)
	// import the component
	Vue.component("tree-select", () => import("vue-treeselect-pl"))
	Vue.component("p-datepicker", () => import("vue-bootstrap-datetimepicker-adizbek"))

	Vue.component("v-style", {
		render: function (createElement) {
			return createElement("style", this.$slots.default)
		}
	})
}

/**
 * @param {VueRouter} router
 */
function addDefaultHomeRouteIfNotExists(router) {
	let homeRoute = router.options.routes.find((x) => x.name === "home")

	if (!homeRoute)
		router.addRoutes([
			{ path: "/", component: HomePage, name: "home", meta: { platonLayout: true, publicRoute: true } }
		])
}

/**
 * @param {VueRouter} router
 * @param {PlatonCoreOptions} options
 */
export function registerPlatonRoutes(router, options) {
	const originalPush = Router.prototype.push
	Router.prototype.push = function (location) {
		return originalPush.call(this, location).catch((err) => {
			if (err.name !== "NavigationDuplicated") throw err
		})
	}

	router.addRoutes([
		{ path: "/login", component: LoginPage, name: "login", meta: { publicRoute: true } },
		{ path: "/tables/:name", component: PlatonTableView, name: "table", meta: { platonLayout: true } },
		{ path: "/forms/:name/:id?", component: PlatonFormView, name: "form", meta: { platonLayout: true } },
		{ path: "/pages/:name", component: PlatonPageView, name: "page", meta: { platonLayout: true } },

		{ path: "/public/tables/:name", component: PlatonTableView, name: "public-table", meta: { publicRoute: true } },
		{
			path: "/public/forms/:name/:id?",
			component: PlatonFormView,
			name: "public-form",
			meta: { publicRoute: true }
		},
		{ path: "/public/pages/:name", component: PlatonPageView, name: "public-page", meta: { publicRoute: true } },

		{
			path: "/system/file-manager/:path?",
			component: FileManagerPage,
			name: "file-manager-page",
			meta: { platonLayout: true }
		},
		{
			path: "/system/resizable-page",
			component: ResizablePage,
			name: "resizable-page",
			meta: { platonLayout: true }
		},
		{
			path: "/system/profile",
			component: ProfilePage,
			name: "profile-page",
			meta: { platonLayout: true }
		},

		{
			path: "/instructions/:main?/:sub?",
			component: InstructionsPage,
			name: "instruction",
			meta: { platonLayout: true }
		},
		{ path: "/system/:path", component: BlankComponent, name: "system", meta: { platonLayout: true } },
		{ path: "*", component: PlatonNotFoundPage, name: "404", params: { resource: "page" } }
	])

	addDefaultHomeRouteIfNotExists(router)
}

function initPlugins(options, Vue) {
	const pluginCore = new PlatonPluginCore(options)

	window.PlatonPluginCore = pluginCore
	Vue.prototype.$plugins = pluginCore

	let plugins = [...PlatonCorePlugins, ...(Array.isArray(options.plugins) ? options.plugins : [])].sort(
		(a, b) => a.priority - b.priority
	)

	pluginCore.preloadPlugins(plugins)
	pluginCore.triggerEvent(PluginEvents.AfterInit)
}

/**
 *
 * @param Vue
 * @param {PlatonCoreOptions} options
 */
export function extendVuePrototype(Vue, options) {
	Vue.prototype.$platonApp = PlatonVueInstance(Vue, options)

	Object.defineProperty(Vue.prototype, "$user", {
		get() {
			return getUser()
		}
	})

	Vue.prototype.$http = $http

	const $api = CreateHttpClient({ baseURL: API_ENDPOINT })
	setPlatonApiClient($api)
	Vue.prototype.$api = $api

	Vue.prototype.$apiUrl = function (endpoint) {
		return API_ENDPOINT + "/" + trimSlash(endpoint)
	}
	Vue.prototype.$media = function (path) {
		return pathJoin(FRONTEND_URL, "media/store", path)
	}
	Vue.prototype.$const = CONSTANTS
	Vue.prototype.$appName = APP_NAME
	Vue.prototype.$appVersion = APP_VERSION
	Vue.prototype.$copyright = COPYRIGHT
	Vue.prototype.$platonFile = platonFileUrl
	Vue.prototype.$downloadFile = downloadPlatonFile
	Vue.prototype.$l = $language
	Vue.prototype.$f = function () {
		let filter = arguments[0]
		let args = Array.from(arguments).slice(1)
		return this.$options.filters[filter](...args)
	}
	Vue.prototype.$m = moment
	Vue.prototype.$ll = $parametrizedLanguage
	Vue.prototype.$isPageBlank = function () {
		if (this.$route.path === "/") {
			return getUser() == null
		} else if (this.$route.name === "form") {
			return (this.$route.query["_target"] || "blank").includes("blank")
		} else if (this.$route.name === "page") {
			return (this.$route.query["_target"] || "").includes("blank")
		}

		return false
	}

	HTMLElement.prototype.downloadBlob = downloadBlob

	VueRuntimeScripts(Vue)
	initPlugins(options, Vue)

	window.PlatonMixins = {
		LoginMixin,
		LoginPasswordProviderMixin,
		EimzoProviderMixin,
		KeycloakProviderMixin,
		pickUserOrganization,
		ImageCropperMixin
	}
}

export function registerGlobalLibs() {
	window.moment = moment
	window.$ = window.jQuery = jQuery
}
