<template>
    <div>
        <b-form-input v-bind="inputOptions" :list="listName" />

        <datalist :id="listName">
            <option v-for="option in options" :key="option" :value="option" />
        </datalist>
    </div>
</template>

<script>
let listId = 1

export default {
    name: "PlatonInputDataList",

    props: {
        options: {
            type: Array
        },

        inputOptions: {
            type: Object
        }
    },

    data() {
        return {
            listName: `platon-datalist-${listId++}`
        }
    }
}
</script>
