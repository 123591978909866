<template>
    <BaseControl v-bind="$attrs" :errors="combinedErrors">
        <b-form-textarea
            :disabled="disabled"
            :value="local"
            @input="onInput"
            :id="id"
            :placeholder="placeholder"
            :readonly="readonly"
            class="form-control"
        />
    </BaseControl>
</template>

<script>
import BaseControl from "@Platon/components/form/controls/BaseControl.vue"
import InputControlMixin from "@Platon/mixins/InputControlMixin"
import ValidationMixin from "@Platon/mixins/ValidationMixin"

export default {
    name: "TextareaControl",
    components: { BaseControl },

    mixins: [ValidationMixin, InputControlMixin],

    methods: {
        onInput(val) {
            this.markAsDirty()
            this.updateLocal(val)
        }
    }
}
</script>

<style></style>
