<template>
    <BaseControl v-bind="$attrs" :errors="combinedErrors" ref="base">
        <MultiKeyValueControl v-model="rows" :definition="keyValue" />
    </BaseControl>
</template>

<script>
import MultiKeyValueControl from "@Platon/components/form/controls/MultiKeyValueControl.vue"
import BaseControl from "@Platon/components/form/controls/BaseControl.vue"
import InputControlMixin from "@Platon/mixins/InputControlMixin"
import ValidationMixin from "@Platon/mixins/ValidationMixin"

export default {
    name: "KeyValueControl",
    components: { BaseControl, MultiKeyValueControl },

    mixins: [InputControlMixin, ValidationMixin],

    props: {
        value: {
            type: Object
        }
    },

    data() {
        let rows = []

        try {
            rows = this.fromObjectToRows(this.value || {})
        } catch {}

        return {
            keyValue: [
                { key: "key", value: "Key" },
                { key: "value", value: "Value" }
            ],

            rows: rows
        }
    },

    methods: {
        /**
         * @param {Object} object
         */
        fromObjectToRows(object) {
            return Object.keys(object).reduce((arr, key) => {
                arr.push({ key: key, value: object[key] })

                return arr
            }, [])
        },

        /**
         * @param {KeyValue[]} rows
         */
        fromRowsToObject(rows) {
            return rows.reduce((obj, item) => {
                if (item.key === undefined || (typeof item.key === "string" && !item.key.trim().length)) {
                    return obj
                }

                obj[item.key] = item.value === undefined ? "" : item.value

                return obj
            }, {})
        }
    },

    watch: {
        rows(val) {
            this.$emit("input", this.fromRowsToObject(val))
        }
    }
}
</script>

<style></style>
