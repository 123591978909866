<template>
    <div class="d-flex align-items-center justify-content-center flex-column p-2" style="min-height: 200px">
        <b-spinner style="width: 4rem; height: 4rem" variant="primary" label="Spinning"></b-spinner>
    </div>
</template>
<script>
export default {
    name: "LoadingView"
}
</script>
