<template>
    <div class="error">
        <h1 class="head">404</h1>
        <h3 class="body">{{ $l("platon.page_not_found", "Саҳифа топилмади") }}</h3>
        <router-link class="mt-3 btn btn-primary" :to="'/'"
            >{{ $l("platon.back_to_home", "Бош саҳифага қайтиш") }}
        </router-link>
    </div>
</template>

<script>
export default {
    name: "PlatonNotFoundPage"
}
</script>

<style scoped>
.error {
    display: block;
    text-align: center;
    padding-top: 100px;
}
.head {
    font-size: 40px;
    color: #335d99;
}
.body {
    font-size: 24px;
    color: #335d99;
}
.route {
    font-size: 20px;
}
</style>
