<template>
    <div class="DialogWithOTPVerification">
        <Modal :has-footer="true" :footer-actions="modalActions" :can-cancel="false">
            <template #title> {{ $l("platon.confirm-one-time-code", "Бир марталик кодни тасдиқланг") }} </template>
            <AuthorizationWithMFA
                @authorization="setAuthorizationOtpCode"
                :state="verificationState"
                @authorizationSubmit="authorizationSubmit"
            />
        </Modal>
    </div>
</template>

<script>
import Modal from "../extended/Modal.vue"

import AuthorizationWithMFA from "../../views/profile/sections/MultiAuthentication/AuthorizationWithMFA.vue"
import LoginPasswordProviderMixin from "./LoginPasswordProviderMixin"
import { API_BASE_URL, LOGIN_ENDPOINT } from "@Platon/const"

export default {
    name: "DialogWithOTPVerification",
    mixins: [LoginPasswordProviderMixin],
    components: {
        Modal,
        AuthorizationWithMFA
    },
    data() {
        return {
            otpCode: null,
            isModalOpen: true,
            isLoading: false,

            verificationState: {
                isError: false,
                verified: false
            }
        }
    },
    methods: {
        getOtpCode() {
            return String(this.otpCode).length
        },
        setAuthorizationOtpCode(otpCode) {
            this.otpCode = otpCode
            this.verificationState.isError = false
        },
        closeVerificationModal() {
            this.$emit("close")
            this.$attrs.onClose()
        },
        getRequestConfig(parcel) {
            return {
                "login-password": {
                    to: LOGIN_ENDPOINT,
                    request: {
                        body: {
                            username: parcel.username,
                            password: parcel.password,
                            otp: this.otpCode
                        },
                        params: {}
                    }
                },
                "login-EDS": {
                    to: "/auth/eimzo",
                    request: {
                        body: parcel.signedPcks,
                        params: { otp: this.otpCode },
                        headers: { "Content-Type": "text/plain" }
                    }
                }
            }
        },
        async authorizationSubmit() {
            await this.loginWithOTPVerification()
        },
        async loginWithOTPVerification(hideOnSuccess) {
            if (this.getOtpCode() !== 6) this.isError = true

            try {
                const { parcel, onSuccess } = this.$attrs
                this.isLoading = true

                const requestConfiguration = this.getRequestConfig(parcel)[parcel.type || "login-password"]

                const verificationResponse = await this.$http.post(
                    requestConfiguration.to,
                    requestConfiguration.request.body,
                    {
                        baseURL: API_BASE_URL,
                        params: requestConfiguration.request.params,
                        headers: requestConfiguration.request.headers
                    }
                )

                this.isModalOpen = false
                this.verified = true
                this.$emit("close")
                onSuccess(verificationResponse)
                hideOnSuccess && hideOnSuccess()
            } catch (error) {
                this.isError = false
                this.verificationState.isError = true
                throw new Error(error)
            } finally {
                this.isLoading = false
            }
        }
    },
    computed: {
        modalActions() {
            return [
                {
                    text: "Cancel",
                    handler: () => this.closeVerificationModal()
                },
                {
                    text: "Подтвердить",
                    isLoading: this.isLoading,
                    handler: async (hideModal) => {
                        if (this.getOtpCode() >= 6) {
                            await this.loginWithOTPVerification(hideModal)
                            return false
                        } else {
                            this.verificationState.isError = true
                            return true
                        }
                    }
                }
            ]
        }
    }
}
</script>

<style lang="scss" scoped>
.DialogWithOTPVerification {
    & .AuthWithMFA {
        width: 100%;
        height: 270px;

        & .AuthorizationWithMFA {
            border-color: var(--pl-btn-primary) !important;
        }
    }
}
</style>
