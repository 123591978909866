<template>
    <div class="d-flex align-items-center">
        <b-skeleton width="30px" height="30px" type="avatar"></b-skeleton>
        <b-skeleton class="ml-2" v-if="!menuCollapsed" :width="computedWidth"></b-skeleton>
    </div>
</template>
<script>
import { mapGetters } from "vuex"

export default {
    name: "MenuSkeleton",

    props: {
        width: {
            default: 0
        }
    },

    computed: {
        ...mapGetters({
            menuCollapsed: "platon/menuCollapsed"
        }),

        computedWidth() {
            return 65 + this.width + "%"
        }
    }
}
</script>
