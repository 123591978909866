import FilePreviewModal from "@Platon/components/misc/preview/FilePreviewModal.vue"

export default {
	methods: {
		/**
		 * @param {PlatonFile} remoteFile
		 */
		openFilePreviewModal(remoteFile) {
			this.$modal.show(
				FilePreviewModal,
				{
					remoteFile
				},
				{
					height: "90%",
					width: window.innerWidth < 768 ? window.innerWidth - 40 : 600,
					maxHeight: window.innerHeight * 0.9
				}
			)
		}
	}
}
