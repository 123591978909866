<template>
    <div class="platon-table-no-data">
        {{ $l("platon.table_no_data", "Жадвалда маълумот йўқ") }}
    </div>
</template>

<script>
export default {
    name: "TableNoData"
}
</script>

<style></style>
