import { mapGetters } from "vuex"

const PERMISSIONS = {
	PLATON_ADMIN: "platon.admin"
}

export default {
	computed: {
		...mapGetters({
			user: "platon/user"
		}),

		/**
		 * @return {String[]}
		 */
		permissions() {
			try {
				return this.user.permissions
			} catch (e) {
				return []
			}
		},

		isAdmin() {
			return this.hasPermission(PERMISSIONS.PLATON_ADMIN) && !this.$store.state.platon.forceUserMode
		}
	},

	methods: {
		hasPermission(permission) {
			return this.permissions.indexOf(permission) >= 0
		},

		/**
		 * @param object
		 * @param {Number} object.projectId
		 * @return {boolean}
		 */
		isMyProject(object) {
			if (!object) return false

			if (Number.isInteger(object.projectId)) {
				return this.user.projectId === object.projectId
			} else {
				console.warn("Project id not found for ", object)
			}

			return false
		}
	}
}
