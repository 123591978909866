import moment from "moment"

export default {
	methods: {
		addDays(day) {
			this.local = moment(this.local, this.dateFormat).add(day, "day").format(this.dateFormat)
		},

		onPickerShow() {
			// if (!this.$refs.input || !this.$refs.input.$el) {
			// 	return
			// }
			//
			// let $this = $(this.$refs.input.$el);
			// let bounds = this.$refs.input.$el.getBoundingClientRect();
			//
			// const datepicker = $('body').find('.bootstrap-datetimepicker-widget:last');
			//
			// datepicker.css({
			// 	'top': $($this).outerHeight() + bounds.top + 'px',
			// 	'bottom': 'auto',
			// 	'left': bounds.left + 'px'
			// });
			// 		if (datepicker.hasClass('bottom')) {
			// 			let top = $($this).offset().top + $($this).outerHeight();
			// 			let left = $($this).offset().left;
			// 			datepicker.css({
			// 				'top': top + 'px',
			// 				'bottom': 'auto',
			// 				'left': left + 'px'
			// 			});
			// 		} else if (datepicker.hasClass('top')) {
			// 			let top = $($this).offset().top - datepicker.outerHeight();
			// 			let left = $($this).offset().left;
			// 			datepicker.css({
			// 				'top': top + 'px',
			// 				'bottom': 'auto',
			// 				'left': left + 'px'
			// 			});
			// 		}
		}
	}
}
