<template>
    <div>
        <b-tooltip
            custom-class="cell-tooltip"
            :key="index"
            :target="tooltip.target"
            boundary="window"
            triggers="hover"
            show
            v-for="(tooltip, index) in tooltipList"
            v-html="tooltip.text"
        >
            <span v-html="tooltip.text" />
        </b-tooltip>
    </div>
</template>

<script>
import EventBusMixin from "@Platon/mixins/EventBusMixin"

export default {
    name: "TooltipManager",

    mixins: [EventBusMixin],

    data() {
        return {
            tooltips: new Map(),
            tooltipList: []
        }
    },

    mounted() {
        this.$onEvent("PLATON:TOOLTIP:SHOW", this.showToolTip)
        this.$onEvent("PLATON:TOOLTIP:HIDE", this.hideTooltip)
    },

    methods: {
        showToolTip(owner, tooltip) {
            this.tooltips.set(owner, tooltip)
            this.tooltipList = Array.from(this.tooltips.values())
        },

        hideTooltip(owner) {
            let hasTooltip = this.tooltips.has(owner)

            if (hasTooltip) {
                this.tooltips.delete(owner)
            }

            this.tooltipList = Array.from(this.tooltips.values())
        }
    }
}
</script>

<style></style>
