import { getUser } from "@Platon/core/core"

/**
 * @param {string} permission
 * @return {boolean}
 */
export function has_perm(permission) {
	return getUser().permissions.includes(permission)
}

/**
 * @param {string} permission
 * @return {boolean}
 */
export function has_any_perm(...permission) {
	let perms = new Set(permission)

	return getUser().permissions.findIndex((p) => perms.has(p)) > -1
}
