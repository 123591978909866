<template>
    <BaseControl v-bind="$attrs" :errors="combinedErrors">
        <b-input
            :value="local"
            @change="updateLocal"
            :id="id"
            :placeholder="placeholder"
            :disabled="disabled"
            :readonly="readonly"
            :autocomplete="autocomplete"
            class="form-control"
            :type="showPassword ? 'text' : 'password'"
        />

        <div class="position-absolute" style="top: 7px; right: 7px" @click="showPassword = !showPassword">
            <i :class="[showPassword ? 'fa-eye-slash' : 'fa-eye']" class="fa"></i>
        </div>
    </BaseControl>
</template>

<script>
import BaseControl from "@Platon/components/form/controls/BaseControl.vue"
import InputControlMixin from "@Platon/mixins/InputControlMixin"
import ValidationMixin from "@Platon/mixins/ValidationMixin"

export default {
    name: "PasswordControl",
    components: { BaseControl },

    mixins: [InputControlMixin, ValidationMixin],

    data() {
        return {
            showPassword: false
        }
    },

    props: {
        autocomplete: {
            default: "new-password"
        }
    }
}
</script>

<style></style>
