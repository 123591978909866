<template>
    <item @click="$emit('click')" link-class="d-flex flex-row">
        <div class="dropdown-icon" v-if="icon">
            <i v-if="icon" :class="icon" />
        </div>

        <slot />
    </item>
</template>

<script>
import { BDropdownItem } from "bootstrap-vue"

export default {
    name: "DropdownItem",
    components: {
        item: BDropdownItem
    },
    props: {
        icon: {}
    }
}
</script>
