/** @type {Map<Vue.Component, Array<{type: String, listener: function, target: HTMLElement}>>} */
let _hotkeys = new Map()

export default {
	beforeDestroy() {
		if (_hotkeys.has(this)) {
			for (let hotkey of _hotkeys.get(this)) {
				hotkey.target.removeEventListener(hotkey.type, hotkey.listener)
			}

			_hotkeys.delete(this)
		}
	},

	methods: {
		/**
		 * @param {string|string[]} cmd
		 * @param {function} callback
		 * @param options
		 * @param {HTMLElement} options.target
		 * @param {string} options.type
		 */
		hotkey(cmd, callback, options = {}) {
			let handle = (cmd) => {
				let keys = cmd.toLowerCase().split("_")
				let type = options.type || "keydown"

				let listener = (/** KeyboardEvent */ event) => {
					let alt = keys.includes("alt")
					let ctrl = keys.includes("ctrl")
					let shift = keys.includes("shift")

					let conditions = []

					if (alt) {
						conditions.push(event.altKey)
					}

					if (ctrl) {
						conditions.push(event.ctrlKey)
					}

					if (shift) {
						conditions.push(event.shiftKey)
					}

					let key = keys.filter((k) => !["alt", "control", "shift"].includes(k))
					let keyName = key.pop() || ""

					if (conditions.every((c) => c === true) && keyName === event.code.toLowerCase()) {
						callback(event)
					}
				}

				/** @type {HTMLElement} */
				let target = options.target || window
				target.addEventListener(type, listener)

				if (!_hotkeys.has(this)) {
					_hotkeys.set(this, [])
				}

				_hotkeys.get(this).push({ type, listener, target })
			}

			if (Array.isArray(cmd)) {
				for (let key of cmd) {
					handle(key)
				}
			} else {
				handle(cmd)
			}
		}
	}
}
