import { localeInfo } from "@Platon/core/translations"
import { DEFAULT_THEME, DEFAULT_THEME_COLOR } from "@Platon/const"

function isMenuCollapsed() {
	const isDimensionMobile = window.innerWidth < 768

	return isDimensionMobile || !!localStorage.getItem("menuCollapsed")
}

export default {
	theme: localStorage.getItem("platon_theme") || DEFAULT_THEME,
	themeColor: localStorage.getItem("platon_theme_color") || DEFAULT_THEME_COLOR,

	menu: [],
	customMenu: [],

	menuCollapsed: isMenuCollapsed(),

	user: null,
	locale: localeInfo.locale,

	isAuth: false,
	forceUserMode: false,
	authAttempt: false
}
