<template>
    <draggable
        v-model="inputVal.columns"
        v-click-outside="clearCollapsedColumnId"
        @change="onDragChange"
        handle=".handle"
        draggable=".enabled-dnd"
    >
        <transition-group tag="div" class="filter-column-wrapper">
            <div
                class="filter-column-field"
                v-for="column of inputVal.columns"
                :key="column.id"
                :class="{ 'active-column-wrapper': isColumnCollapsed(column.id), 'enabled-dnd': column.isDraggable }"
            >
                <div class="d-flex justify-content-between align-items-center">
                    <i v-if="column.isDraggable" class="fa fa-align-justify handle column-filter-handler mr-2"></i>
                    <b-form-checkbox
                        :id="`id-${column.id}`"
                        :value="true"
                        :disabled="column.disabled"
                        :unchecked-value="false"
                        :checked="column.enabled"
                        @input="onFilterChange($event, column)"
                        :name="`name-${column.id}`"
                        class="filter-column-checkbox"
                    >
                        <input
                            :value="column.title"
                            class="filter-column-input"
                            @change="changeTitle($event, column)"
                        />
                    </b-form-checkbox>
                    <div class="d-flex align-items-center">
                        <span v-if="!column.isDraggable" class="m-0 filter-column-fixed-status ml-3">Fixed</span>
                        <i
                            v-if="column.children.length > 0"
                            class="fas fa-angle-down column-icon ml-3"
                            :class="{ 'active-column': isColumnCollapsed(column.id) }"
                            @click="changeCollapsedColumn(column)"
                        ></i>
                    </div>
                </div>
                <transition name="column-slide-fade">
                    <div class="ml-4" v-if="column.children.length > 0" v-show="isColumnCollapsed(column.id)">
                        <table-filter-columns
                            class="pt-2"
                            :orders="inputVal.orders"
                            :column="column"
                            :columns="column.children"
                            :disabled-columns="disabledColumns"
                            @checkbox-toggler="getChildValue($event, column)"
                            @re-order="reOrder"
                            @change-titles="changeChildTitle"
                        />
                    </div>
                </transition>
            </div>
        </transition-group>
    </draggable>
</template>

<script>
import draggable from "vuedraggable"

export default {
    name: "TableFilterColumns",
    props: ["columns", "column", "disabledColumns", "orders"],
    components: { draggable },

    data() {
        return {
            inputVal: {
                columns: this.columns,
                orders: this.orders,
                titles: {}
            },
            collapsedColumnId: null
        }
    },

    methods: {
        clearCollapsedColumnId() {
            this.collapsedColumnId = null
        },
        onFilterChange(value, column) {
            const index = this.inputVal.columns.findIndex((el) => el.id === column.id)
            if (index >= 0) {
                this.inputVal.columns[index].enabled = value
                this.disableChildren(this.inputVal.columns[index], value)
            }

            this.$emit("checkbox-toggler", this.inputVal.columns)
        },
        getChildValue(childInputVal, column) {
            const index = this.inputVal.columns.findIndex((el) => el.id === column.id)
            if (index >= 0) this.inputVal.columns[index].children = childInputVal
        },
        disableChildren(columns, value) {
            let data = Array.isArray(columns) ? columns : columns.children
            data.map((el) => {
                if (el.children.length > 0) {
                    this.disableChildren(el.children, value)
                }

                return (el.disabled = !value)
            })
        },
        changeCollapsedColumn(column) {
            if (this.isColumnCollapsed(column.id)) {
                this.collapsedColumnId = null
            } else {
                this.collapsedColumnId = column.id
            }
        },
        isColumnCollapsed(id) {
            return id === this.collapsedColumnId
        },
        onDragChange() {
            Object.assign(this.inputVal.orders, this.transformColumns())
            this.$emit("re-order", this.inputVal.orders, this.inputVal.columns)
        },
        reOrder(childrenOrders) {
            this.inputVal.orders = childrenOrders
        },
        transformColumns() {
            return this.inputVal.columns.reduce((acc, curr, index) => {
                acc[curr.id] = index

                return acc
            }, {})
        },
        changeTitle(e, column) {
            const value = e.target.value
            Object.assign(this.inputVal.titles, { [column.id]: value })
            this.changeColumnTitle(value, column.id)
            this.$emit("change-titles", this.inputVal.titles)
        },
        changeChildTitle(titles) {
            Object.assign(this.inputVal.titles, titles)
            this.$emit("change-titles", this.inputVal.titles)
        },
        changeColumnTitle(title, id) {
            const index = this.inputVal.columns.findIndex((column) => column.id === id)
            this.inputVal.columns[index].title = title
        }
    },

    watch: {
        columns(values) {
            this.inputVal.columns = values
        },
        orders(values) {
            this.inputVal.orders = values
        }
    }
}
</script>

<style lang="scss">
.column-filter-handler {
    cursor: move;
}
.filter-column-field {
    padding: 8px 10px;
    border-radius: 4px;
    border: 1px solid var(--pl-filter-column-field-border);
}
.filter-column-checkbox {
    width: 100%;
    label {
        width: 100%;
        cursor: pointer;
        &::after,
        &::before {
            top: 0.4rem;
        }
    }
}
.filter-column-input {
    width: 100%;
    padding: 2px 8px;
    border: none;
    color: var(--pl-form-input-text-color);
    background-color: var(--pl-input-bg);
    &:focus {
        outline: none;
        border-bottom: 1px solid var(--pl-column-wrapper);
    }
}
.sortable-chosen {
    transition: all 0.4s;
    transform: rotate(1deg);
    background: var(--pl-input-bg);
}
.sortable-ghost {
    margin-left: 10px;
}

.active-column {
    transition: all 0.3s;
    transform: rotate(180deg);
}

.active-column-wrapper {
    border-color: var(--pl-column-wrapper);
    transition: all 0.3s;
}
.column-icon {
    user-select: none;
    cursor: pointer;
}

.column-slide-fade-enter-active {
    transition: all 0.3s;
    animation: column-children-enter 0.3s;
}
.column-slide-fade-leave-active {
    transition: all 0.3s;
    animation: column-children-leave 0.4s;
}

.column-slide-fade-enter,
.column-slide-fade-leave-to {
    opacity: 0;
    transform: translateX(10px);
}

.filter-column-wrapper {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.filter-column-fixed-status {
    font-size: 11px;
    padding: 1px 4px;
    border-radius: 6px;
    background: var(--pl-input-bg);
    border: 1px solid var(--pl-filter-column-field-border);
}

@keyframes column-children-enter {
    from {
        max-height: 0px;
    }
    to {
        max-height: 100vh;
    }
}
@keyframes column-children-leave {
    from {
        max-height: 100vh;
    }
    to {
        max-height: 0px;
    }
}
</style>
