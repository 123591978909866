let modals = new Map()
let stackIndex = 0

/**
 * @param {KeyboardEvent} ev
 */
window.addEventListener("keydown", (ev) => {
	if (ev.keyCode === 27 && !ev.defaultPrevented) {
		let size = modals.size

		if (size > 0) {
			ev.preventDefault()

			let last = [...modals.values()][size - 1]
			last.$emit("close")
		}
	}
})

export default {
	data() {
		return {
			stackIndex: stackIndex++
		}
	},

	mounted() {
		modals.set(this.stackIndex, this)
	},

	beforeDestroy() {
		modals.delete(this.stackIndex)
	}
}
