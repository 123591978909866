export default {
	inject: {
		form: {
			default: undefined
		}
	},

	mounted() {
		if (this.form) {
			// elements will be mounted after form meta loaded, it should guarantee form has focusedElementName

			if (this.form.formMeta.focusedFieldName === this.item.dataField) {
				this.focus()
			}
		}
	}
}
