<template>
    <Modal :footer-actions="actions">
        <template #title>
            <div>{{ $l("platon.import_modal_title", "Маълумотларни импорт қилиш") }}</div>
        </template>

        <div v-if="meta">
            {{ meta.note }}

            <div class="form-row">
                <div class="col-12">
                    <UploadArea
                        ref="uploadArea"
                        v-model="selectedFile"
                        class="my-3"
                        :upload-endpoint="importEndpoint"
                    />
                </div>
                <div class="col-6" v-if="meta.templateFileId">
                    <div @click="$downloadFile(meta.templateFileId)" class="btn btn-primary d-flex align-items-center">
                        <i class="fa fa-file-excel pr-1" />
                        Шаблонни юклаб олиш
                    </div>
                </div>
                <div class="col-6" v-if="meta.sampleFileId">
                    <div @click="$downloadFile(meta.sampleFileId)" class="btn btn-primary d-flex align-items-center">
                        <i class="fa fa-file-excel pr-1" />
                        Наъмунани юклаб олиш
                    </div>
                </div>
            </div>
        </div>
    </Modal>
</template>

<script>
import Modal from "@Platon/components/extended/Modal.vue"
import UploadArea from "@Platon/components/misc/UploadArea.vue"
import { API_BASE_URL } from "@Platon/const"
import { trimSlash } from "@Platon/core/helpers"

export default {
    name: "ImportFileDialog",
    components: { UploadArea, Modal },

    props: {
        localRoute: {
            type: Object
        }
    },

    data() {
        return {
            selectedFile: null
        }
    },

    chimera: {
        importMeta() {
            return {
                baseURL: "/",
                url: this.importEndpoint
            }
        }
    },

    created() {},

    computed: {
        actions() {
            return [
                {
                    text: "Файлни юклаш",
                    disabled: !this.selectedFile,
                    handler: () => {
                        this.$refs.uploadArea
                            .upload()
                            .then(() => {
                                this.$platonApp.greenToast("Маълумотлар импорт қилинди")

                                this.$emit("close")
                            })
                            .catch(() => {
                                this.$platonApp.errorToast("Маълумотлар импорт қилишда хатолик")
                            })

                        return true
                    }
                },
                {
                    text: "Бекор қилиш"
                }
            ]
        },

        /**
         * @return {{
         *   name: string,
         *   note: string,
         *   sampleFileId: string,
         *   templateFileId: string
         * }}
         */
        meta() {
            return this.$chimera.importMeta.data
        },

        /**
         * @return {string}
         */
        importEndpoint() {
            return API_BASE_URL + trimSlash(this.localRoute.fullPath)
        }
    }
}
</script>

<style></style>
