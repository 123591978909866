<template>
    <div v-if="isAuth"></div>

    <div v-else>
        <PageRenderer :chimera-options="landingPageOptions" />
    </div>
</template>

<script>
import { mapGetters } from "vuex"
import PageRenderer from "@Platon/components/pages/PageRenderer.vue"

export default {
    components: { PageRenderer },

    computed: {
        ...mapGetters({
            isAuth: "platon/isAuth"
        }),

        landingPageOptions() {
            return {
                url: "public/main"
            }
        }
    }
}
</script>
