<template>
    <svg viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_899_2504)">
            <path
                d="M9.33204 0H20.9971C26.8704 11.06 20.9971 21 20.9971 21C5.13262 21 0.157478 31.2783 0.00583252 31.5992H0V9.33333C0 4.1825 4.18192 0 9.33204 0Z"
                :fill="hex1"
            />
            <path
                d="M20.9971 0H32.6621C37.8181 0 41.9942 4.1825 41.9942 9.33333V31.605C41.9942 31.605 31.6006 31.605 20.9971 21C20.9971 21 26.8704 11.06 20.9971 0Z"
                :fill="hex2"
            />
            <path
                d="M21.0029 21C31.6064 31.605 42 31.605 42 31.605V32.6667C42 37.8233 37.8239 42 32.668 42H9.33787C4.18775 42 0 37.8233 0 32.6667V31.605H0.00583252C0.157478 31.2783 5.13262 21 20.9971 21H21.0029Z"
                :fill="hex3"
            />
        </g>
        <defs>
            <clipPath id="clip0_899_2504">
                <rect width="42" height="42" fill="white" />
            </clipPath>
        </defs>
    </svg>
</template>

<script>
export default {
    name: "ThemePicker",
    props: {
        hex1: {
            type: String,
            default: "#000000"
        },
        hex2: {
            type: String,
            default: "#7b7b7b"
        },
        hex3: {
            type: String,
            default: "#bbbbbb"
        }
    }
}
</script>

<style></style>
