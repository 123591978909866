<template>
    <div class="notification" :class="element.type" v-if="element.show">
        <div class="notification_info">
            <component :is="element.component" v-if="element.component" />

            <div v-else>
                <h5 v-html="element.title" />
                <p class="m-0" style="white-space: pre-wrap" v-html="element.description" />
            </div>

            <div v-if="element.actions" class="d-flex notification_actions justify-content-end notification_actions">
                <button
                    type="button"
                    v-for="(btn, index) of element.actions"
                    :key="`${btn.label}${index}`"
                    @click="btn.event(element, updateShow)"
                    :class="[btn.class, element.type, `${element.type}_btn`]"
                >
                    {{ btn.label }}
                </button>
            </div>

            <button
                v-if="element.dismissible"
                class="close-btn"
                :class="`close_${element.type}`"
                @click="closeNotification"
            >
                <i class="fa fa-times"></i>
            </button>
        </div>
    </div>
</template>
<script>
export default {
    name: "Notification",
    props: {
        element: {
            actions: {
                type: Array,
                default: []
            }
        },
        close: Function
    },

    methods: {
        closeNotification() {
            this.close(this.$props.element, this.updateShow)
        },
        updateShow() {
            this.element.show = false
        }
    }
}
</script>
<style lang="scss" scoped>
.notification {
    position: relative;
    padding: 20px;
    border-radius: 4px;
    margin-bottom: 10px;

    &_info {
        width: 100%;
        align-items: center;
        justify-content: space-between;
    }

    &_actions {
        margin-top: 0.75rem;
        gap: 10px;
        align-items: center;

        button {
            outline: none;
            display: flex;
            border-radius: 4px;
            align-items: center;
            justify-content: center;
        }
    }
}

.close-btn {
    border: none;
    font-size: 16px;
    transition: 0.2s ease;
    background: transparent;
    position: absolute;
    top: 6px;
    right: 6px;

    &:hover {
        font-weight: bold;
    }
}

.close_danger {
    color: #49242a;
}

.close_info {
    color: #062c33;
}

.close_warning {
    color: #533f03;
}

.close_success {
    color: #0b2e13;
}

.close_secondary {
    color: #202326;
}

.close_primary {
    color: #002752;
}

.danger {
    color: #721c24;
    background-color: #f8d7da;
    border-color: #f5c6cb;
}

.info {
    color: #0c5460;
    background-color: #d1ecf1;
    border-color: #bee5eb;
}

.warning {
    color: #856404;
    background-color: #fff3cd;
    border-color: #ffeeba;
}

.success {
    color: #155724;
    background-color: #d4edda;
    border-color: #c3e6cb;
}

.secondary {
    color: #383d41;
    background-color: #e2e3e5;
    border-color: #d6d8db;
}

.primary {
    color: #004085;
    background-color: #cce5ff;
    border-color: #b8daff;
}

.danger_btn {
    color: #f05e7c;
    border: 1px solid #f05e7c;
    transition: 0.2s ease;

    &:hover {
        color: #fff;
        background: #f05e7c;
    }

    &:active {
        background: #e04e6c;
    }
}

.warning_btn {
    color: #e86c31;
    border: 1px solid #e86c31;
    transition: 0.2s ease;

    &:hover {
        color: #fff;
        background: #e86c31;
    }

    &:active {
        background: #d85c21;
    }
}

.info_btn {
    color: #00acd6;
    border: 1px solid #00acd6;
    transition: 0.2s ease;

    &:hover {
        color: #fff;
        background: #00acd6;
    }

    &:active {
        background: #009cc6;
    }
}

.success_btn {
    color: #00a852;
    border: 1px solid #00a852;
    transition: 0.2s ease;

    &:hover {
        color: #fff;
        background: #00a852;
    }

    &:active {
        background: #009842;
    }
}

.secondary_btn {
    color: #8b8b8b;
    border: 1px solid #8b8b8b;
    transition: 0.2s ease;

    &:hover {
        color: #fff;
        background: #8b8b8b;
    }

    &:active {
        background: #7b7b7b;
    }
}

.primary_btn {
    color: #027afd;
    border: 1px solid #027afd;
    transition: 0.2s ease;

    &:hover {
        color: #fff;
        background: #027afd;
    }

    &:active {
        background: #006aed;
    }
}
</style>
