<template lang="">
    <div class="sgn">
        <div class="sgn__switch">
            <label class="switch">
                <input :checked="this.isWhiteListEnable" type="checkbox" @click="typeList" />
                <span class="slider"></span>
            </label>
            {{ $l("platon.whitelist-add", "Оқ рўйхатга қўшиш") }}
        </div>
        <div class="sgn__part">
            <TextControl
                :label="$l('platon.eri-number', 'Серия номер ЭЦП')"
                class="form-control-ERI"
                v-model="serialERI"
                @change="handleChangeERIInput"
                placeholder="user1,user2,user3"
            />
            <platon-btn class="sgn__actionbtn" :disabled="isDirty" @click.native="postSerialNumberChanges">
                {{ $l("platon.action-button-verify", "Тасдиқлаш") }}
                <b-spinner
                    v-if="loading"
                    style="width: 1.5rem; height: 1.5rem"
                    class="float-right"
                    label="Floated Right"
                ></b-spinner>
            </platon-btn>
        </div>
        <div class="sgn__list" v-for="item of whiteBlackList" :key="item.imzoSerial">
            <CopyableComponent :text="item.imzoSerial" class="sgn__list--copy">
                <h5>{{ item.imzoSerial }}</h5>
                <span class="sgn__list--btn">
                    <i class="far fa-copy"></i>
                </span>
            </CopyableComponent>
            <p :class="item.type ? 'green' : 'red'" />
        </div>
    </div>
</template>
<script>
import TextControl from "@Platon/components/form/controls/TextControl.vue"
import ToastMixin from "@Platon/mixins/ToastMixin"
import CopyableComponent from "../components/CopyableComponent.vue"

export default {
    name: "SignatureSection",
    mixins: [ToastMixin],
    components: {
        TextControl,
        CopyableComponent
    },
    data() {
        return {
            loading: false,
            serialERI: "",
            userId: this.$user.id,
            isWhiteListEnable: true,
            whiteBlackList: [],
            isDirty: true
        }
    },

    mounted() {
        this.getSignatureList()
    },

    methods: {
        handleChangeERIInput(input, value) {
            this[input] = value
        },
        typeList() {
            this.isWhiteListEnable = !this.isWhiteListEnable
        },
        async postSerialNumberChanges() {
            if (this.serialERI) {
                this.loading = true
                const body = {
                    userId: this.userId,
                    imzoSerial: this.serialERI.split(","),
                    type: this.isWhiteListEnable
                }

                try {
                    await this.$http.post(`/whitelist/add`, body)
                    await this.getSignatureList()
                    this.serialERI = ""
                    this.greenToast(this.$l("platon.form_saved", "Маълумотлар сақланди"))
                    this.loading = false
                } catch (e) {
                    this.loading = false
                    this.errorToast(this.$l("platon.form_server_error", "Серверда хатолик"))
                } finally {
                    this.loading = false
                }
            } else {
            }
        },
        async getSignatureList() {
            try {
                const { data } = await this.$http.post(`/whitelist/get`, { userId: this.userId })
                this.whiteBlackList = data
            } catch (error) {
                throw new Error(error)
            }
        }
    },

    watch: {
        serialERI(val) {
            this.isDirty = !val
        }
    }
}
</script>
<style lang="scss" scoped>
.switch input {
    display: none;
}

.switch {
    display: inline-block;
    width: 40px; /*=w*/
    height: 20px; /*=h*/
    margin: 4px;
    position: relative;
}
.slider {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    border-radius: 30px;
    box-shadow: 0 0 0 2px #b0bcc9, 0 0 4px #b0bcc9;
    cursor: pointer;
    border: 4px solid transparent;
    overflow: hidden;
    transition: 0.2s;
}

.slider:before {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    background-color: #b0bcc9;
    border-radius: 30px;
    transform: translateX(-20px); /*translateX(-(w-h))*/
    transition: 0.2s;
}

input:checked + .slider:before {
    transform: translateX(20px); /*translateX(w-h)*/
    background-color: var(--pl-btn-primary);
}

input:checked + .slider {
    box-shadow: 0 0 0 2px var(--pl-btn-primary), 0 0 4px var(--pl-btn-primary);
}

.sgn {
    display: flex;
    flex-direction: column;
    width: 100%;
    &__switch {
        display: flex;
        margin-left: 1rem;
        margin-bottom: 1rem;
        gap: 1rem;
        align-items: center;
    }

    &__part {
        display: flex;
        align-items: flex-end;
        justify-content: flex-start;
        width: 30%;
        padding-bottom: 25px;

        & .form-control-ERI {
            width: 3%;
            margin-bottom: 0;
        }
    }

    &__actionbtn {
        margin-left: 1rem;
        display: flex;
    }
    &__list {
        display: flex;
        justify-content: space-between;
        width: 30%;
        padding: 5px 20px;
        align-items: center;

        &--copy {
            display: flex;
            gap: 10px;
            align-items: flex-start;

            & h5 {
                font-size: 16px;
                font-weight: 500;
                line-height: 16px;
                letter-spacing: 0em;
            }
        }
        &--btn {
            display: flex;
        }
        & .green {
            background: #46be8a;
            border-radius: 50%;
            height: 15px;
            width: 15px;
        }
        & .red {
            background: #ff070f;
            border-radius: 50%;
            height: 15px;
            width: 15px;
        }
    }
}
</style>
