<template>
    <div class="ProfileViewDivider" id="Plt-ProfileViewDivider" />
</template>

<script>
export default {
    name: "ProfileDivider",
    props: {
        thickness: {
            type: Number,
            default: 1
        },
        unit: {
            type: String,
            default: "px"
        }
    },
    mounted() {
        const divider = document.getElementById("Plt-ProfileViewDivider")
        divider.style.height = `${this.thickness}${this.unit}`
    }
}
</script>

<style lang="scss" scoped>
.ProfileViewDivider {
    height: 2px;
    width: 100%;
    background: #e8ebf2;
}
</style>
