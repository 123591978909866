<template>
    <div>
        <page-loading-view v-if="!isLoaded" />

        <dynamic-page-renderer
            v-show="isLoaded"
            v-if="pageInfo.data"
            :page="pageInfo.data"
            :route="localRoute"
            @pageLoaded="onPageLoaded"
        />

        <ChimeraErrorsView
            v-if="$chimera.pageInfo.status >= 400"
            style="height: 400px"
            :endpoint="$chimera.pageInfo"
            icon="mdi mdi-window-restore"
            :access-denied-text="$l('platon.page_has_no_access', 'Бу саҳифа кўришга рўхсат берилмаган')"
            :not-found-text="$l('platon.page_not_found', 'Саҳифа топилмади')"
        />
    </div>
</template>

<script>
import ChimeraErrorsView from "@Platon/components/misc/ChimeraErrorsView.vue"
import encodedUrlBuilder from "@Platon/core/http/EncodedUrlBuilder"
import DynamicPageRenderer from "@Platon/components/pages/DynamicPageRenderer.vue"
import PageLoadingView from "@Platon/components/pages/PageLoadingView"
import PublicPageMixin from "@Platon/mixins/PublicPageMixin"
import ModalRouteMixin from "@Platon/mixins/ModalRouteMixin"

export default {
    name: "PageRenderer",

    mixins: [PublicPageMixin, ModalRouteMixin],

    components: { PageLoadingView, DynamicPageRenderer, ChimeraErrorsView },

    data() {
        return {
            isLoaded: false
        }
    },

    props: {
        name: {
            type: String,
            default: "wait"
        },

        chimeraOptions: {
            type: Object
        }
    },

    chimera: {
        pageInfo() {
            return (
                this.chimeraOptions || {
                    url: encodedUrlBuilder(`pages/${this.name}`, this.localRoute.query),
                    on: {
                        success: () => {
                            this.$forceUpdate()
                        }
                    }
                }
            )
        }
    },

    methods: {
        reload() {
            this.$chimera.pageInfo.reload()
        },

        onPageLoaded() {
            this.isLoaded = true
        }
    },

    computed: {
        pageOptions() {
            return (
                this.chimeraOptions || {
                    url: encodedUrlBuilder(`pages/${this.name}`, this.localRoute.query)
                }
            )
        }
    }
}
</script>
