export default {
	computed: {
		formData() {
			return this.$form && this.$form.dataHolder
		},

		/**
		 * @return {PlatonForm}
		 */
		formMeta() {
			return this.$form && this.$form.formMeta
		},

		$form() {
			let lookup = this.$parent

			while (lookup) {
				if (lookup.$options.name === "PlatonFormView") {
					return lookup
				}

				lookup = lookup.$parent
			}
		}
	},

	methods: {
		/**
		 * @param {PlatonFormElement} element
		 * @param value
		 */
		notifyDataChange(element, value) {
			if (element.dataField) this.$form.$emit("formSetData", element.dataField, value)
		}
	}
}
