import { downloadPlatonFile, generatePassword, platonFileUrl, platonImageUrl } from "@Platon/core/helpers"

window.downloadPlatonFile = downloadPlatonFile
window.platonFileUrl = platonFileUrl
window.platonImageUrl = platonImageUrl
window.genpass = generatePassword

/**
 * @param {number} args
 * @return number
 */
window.plus = function (...args) {
	return args.reduce((t, c) => parseFloat((t + c).toFixed(10)))
}

/**
 * @param {number} args
 * @return number
 */
window.minus = function (...args) {
	return args.reduce((t, c) => parseFloat((t - c).toFixed(10)))
}

/**
 * @param {number} args
 * @return number
 */
window.multiply = function (...args) {
	return args.reduce((t, c) => parseFloat((t * c).toFixed(10)))
}
/**
 * @param {number} args
 * @return number
 */
window.divide = function (...args) {
	return args.reduce((t, c) => parseFloat((t / c).toFixed(10)))
}
