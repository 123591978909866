import moment from "moment"
import {
	DEFAULT_DATE_FORMAT,
	DEFAULT_DATETIME_FORMAT,
	DEFAULT_SERVER_DATE_FORMAT,
	DEFAULT_SERVER_DATETIME_FORMAT
} from "@Platon/const"
import { isEmpty, or } from "@Platon/core/helpers"

export default {
	data() {
		return {
			validationErrors: [],
			isDirty: false,
			isValid: true
		}
	},

	inject: {
		form: {
			default: undefined
		}
	},

	created() {
		if (this.form)
			try {
				this.form.$data.validationElements.add(this)
			} catch (e) {
				console.warn("Did you use FomData mixin?")
			}
	},

	mounted() {
		this.$watch(
			"local",
			(v) => {
				this.handleValidation(or(v, ""))
			},
			{
				immediate: true
			}
		)

		this.$watch("isDirty", (v) => {
			this.handleValidation(or(this.local, ""))
		})
	},

	beforeDestroy() {
		if (this.form)
			try {
				this.form.$data.validationElements.delete(this)
			} catch (e) {
				console.warn("Did you use FomData mixin?")
			}
	},

	methods: {
		markAsDirty() {
			if (!this.isDirty) {
				this.isDirty = true
			}
		},

		validate() {
			this.handleValidation(or(this.local, ""))

			return this.isValid
		},

		/**
		 * @param val Input being valuated
		 */
		handleValidation(val) {
			const type = this.$attrs["element-type"]

			if (type !== "form_elements") return

			// console.warn('Validation check', this.validationList)
			let errors = []

			if (!this.item) return

			let {
				isRequired,
				maxLength,
				minLength,
				minDate,
				maxDate,
				maxDatetime,
				minDatetime,
				minCount,
				maxCount,
				minValue,
				maxValue
			} = this.item

			if (typeof this.item.customValidator === "function") {
				const result = this.item.customValidator(val, this.item)

				if (result !== undefined && result !== null) errors.push(result)
			}

			if (isRequired && this.item && this.item.type === "checkbox" && val !== true) {
				errors.push(this.$l("platon.validation_check_required", "Танлаш керак"))
			} else if (!this.form.editMode && this.item.type === "password" && val.length < 6) {
				errors.push(this.$l("platon.password_min_length", "Парол 6та дан кам бўлмаслиги керак"))
			} else if (isRequired && isEmpty(val)) {
				errors.push(this.$l("platon.validation_required", "Майдонни тўлдириш керак"))
			} else if (this.item && this.item.type === "raw_json" && !isEmpty(val)) {
				if (!Array.isArray(val) && !(typeof val === "object" && val !== null)) {
					try {
						JSON.parse(val)
					} catch (e) {
						errors.push(this.$l("platon.validation_json_format", "Хато JSON киритилган"))
					}
				}
			} else if (this.item) {
				if (this.item.type === "date" || this.item.type === "datetime") {
					let validationDateFormat = this.item.type
						? DEFAULT_SERVER_DATE_FORMAT
						: DEFAULT_SERVER_DATETIME_FORMAT
					let valueFormat = this.item.type ? DEFAULT_DATE_FORMAT : DEFAULT_DATETIME_FORMAT
					let selectedDate = moment(val, this.item.format || valueFormat)

					if (this.item.type === "datetime") {
						minDate = minDatetime
						maxDate = maxDatetime
					}

					if (minDate instanceof Date) {
						minDate = moment(minDate).format(validationDateFormat)
					}

					if (maxDate instanceof Date) {
						maxDate = moment(maxDate).format(validationDateFormat)
					}

					if (minDate && selectedDate.isBefore(moment(minDate, validationDateFormat))) {
						errors.push(
							this.$ll("platon.validation_min_date", [minDate], "Сана {0} дан катта бўлиши керак")
						)
					}

					if (maxDate && selectedDate.isAfter(moment(maxDate, validationDateFormat))) {
						errors.push(
							this.$ll("platon.validation_max_date", [maxDate], "Сана {0} дан кичик бўлиши керак")
						)
					}
				} else if (this.item.type === "file" || this.item.type === "image") {
					minCount = Number(minCount) || 0
					maxCount = Number(maxCount) || Number.MAX_SAFE_INTEGER

					if (minCount && val.length < minCount) {
						errors.push(
							this.$ll(
								"platon.validation_file_min_count",
								[minCount],
								"Файллар сони {0} та ёки ундан кўп бўлиши керак"
							)
						)
					} else if (maxCount && val.length > maxCount) {
						errors.push(
							this.$ll(
								"platon.validation_file_max_count",
								[maxCount],
								"Файллар сони {0} та ёки ундан кам бўлиши керак"
							)
						)
					}
				} else if ((!isEmpty(val) && this.item.type === "number") || this.item.type === "int") {
					minValue = parseFloat(minValue)
					maxValue = parseFloat(maxValue)

					if (Number.isFinite(minValue) && parseFloat(val) < minValue) {
						errors.push(`Қиймат ${minValue} дан катта ёки тенг бўлиши керак`)
					} else if (Number.isFinite(maxValue) && parseFloat(val) > maxValue) {
						errors.push(`Қиймат ${maxValue} дан кичик ёки тенг бўлиши керак`)
					}
				}
			}

			if (minLength && val.length < Number(minLength)) {
				errors.push(
					this.$ll(
						"platon.validation_min_length",
						[minLength],
						"Майдонни узунлиги {0} та ёки ундан кўп бўлиши керак"
					)
				)
			}

			if (maxLength && val.length > Number(maxLength)) {
				errors.push(
					this.$ll(
						"platon.validation_max_length",
						[maxLength],
						"Майдонни узунлиги {0} та ёки ундан кам бўлиши керак"
					)
				)
			}

			if (this.isDirty) {
				this.validationErrors = errors
			}

			this.isValid = errors.length === 0
		}
	}
}
