/**
 * @param sizeText
 * @return {number}
 */
import { errorToast } from "@Platon/core/functions/toast"
import { $parametrizedLanguage } from "@Platon/core/translations"
import { prettyBytes } from "@Platon/core/helpers"

export function sizeKbToByte(size) {
	size = parseInt(size, 10)

	if (size < 0 || !Number.isInteger(size)) {
		return Number.MAX_SAFE_INTEGER
	}

	return size * 1024
}

/***
 * @param {string} size
 */
export function limitFileSizeErrorToast(size) {
	errorToast(
		$parametrizedLanguage(
			"platon.validation_upload_max_size",
			[prettyBytes(sizeKbToByte(size))],
			`Файл ўлчами {0} гача бўлиши мумкин`
		)
	)
}

export default {
	methods: {
		/**
		 * @param {FileList|[]} files
		 */
		onFilesSelected(files) {
			let maxExceed = false
			let i = 0

			for (let file of files) {
				if (this.value.length + i >= this.maxFilesCount) {
					return
				}

				i++

				if (file.size > this.maxFileSize) {
					maxExceed = true
					continue
				}

				this.selectedFiles.push({
					id: (Math.random() * 1000000).toString(16),
					file
				})
			}

			if (maxExceed) limitFileSizeErrorToast(this.maxFileSizeInText)
		}
	},

	computed: {
		maxFilesCount() {
			return Number(this.item.maxCount) || Number.MAX_SAFE_INTEGER
		},

		minFilesCount() {
			return Number(this.item.minCount) || 0
		},

		canSelectFile() {
			let length = Array.isArray(this.value) ? this.value.length : 0

			return length < this.maxFilesCount
		},

		/**
		 * @return {string}
		 */
		maxFileSizeInText() {
			return this.item.maxSize
		},

		allowedExtensions() {
			// pdf,doc,xls ==> .pdf,.doc,.xls
			let ext = this.item.allowedExtensions || []

			return ext
				.map((x) => {
					if (x === "*") return "*"

					return "." + x
				})
				.join(",")
		},

		maxFileSize() {
			sizeKbToByte(this.item.maxSize)
		}
	}
}
