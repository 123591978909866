import Vue from "vue"
import VueRouter from "vue-router"

Vue.use(VueRouter)

const router = new VueRouter({
	mode: "history",
	base: process.env.VUE_APP_ROUTER_BASE_URL,

	routes: [
		// {
		// 	path: "/",
		// 	component: { template: `<div>sss</div>` },
		// 	name: "home",
		// 	meta: { platonLayout: true }
		// },
		{
			path: "/demo/code-editor",
			component: () => import("@Platon/demo/TestCodeEditor"),
			meta: { auth: false, platonLayout: false }
		},
		{
			path: "/demo/test-table",
			component: () => import("@Platon/demo/TestTablePage"),
			meta: { auth: false, platonLayout: false }
		},
		{
			path: "/demo/test-events",
			component: () => import("@Platon/demo/TestEventsPage"),
			meta: { auth: false, platonLayout: false }
		},
		{
			path: "/demo/test-dynamic-components",
			component: () => import("@Platon/demo/TestDynamicComponentsPage"),
			meta: { auth: false, platonLayout: false }
		},
		{
			path: "/demo/test-resizable",
			component: () => import("@Platon/demo/TestResizablePage"),
			meta: { auth: false, platonLayout: false }
		},
		{
			path: "/demo/vue-editor",
			component: () => import("@Platon/demo/TestVueEditor.vue"),
			meta: { auth: false, platonLayout: false }
		},
		{
			path: "/404",
			name: "404",
			props: true,
			component: () => import("@Platon/components/pages/PlatonNotFoundPage"),
			meta: { platonLayout: false }
		}
	]
})

export default router
