<template>
    <base-control v-bind="$attrs" :errors="combinedErrors">
        <input
            ref="input"
            type="text"
            :placeholder="item.placeholder"
            v-model="local"
            class="form-control"
            :disabled="isDisabled"
            :readonly="isReadOnly"
        />
    </base-control>
</template>

<script>
import BaseControl from "@Platon/components/form/controls/BaseControl.vue"
import InputControl2Mixin from "@Platon/mixins/InputControl2Mixin"
import ValidationMixin from "@Platon/mixins/ValidationMixin"

export default {
    name: "RawJsonControl",
    components: { BaseControl },

    mixins: [InputControl2Mixin, ValidationMixin],

    props: {
        value: {}
    },

    computed: {
        local: {
            get() {
                if (Array.isArray(this.value) && typeof this.value === "object" && this.value !== null) {
                    return JSON.stringify(this.value)
                }

                return this.value
            },

            set(value) {
                this.markAsDirty()

                this.$emit("input", value)
            }
        }
    }
}
</script>
