import lazyThemeChanger from "./lazy-theme-changer"

const themes = {
	precious: () =>
		import(
			'vue-style-loader?{"injectType":"styleTag","attributes":{"data-theme":"precious"}}!../../assets/themes/types/precious/precious.scss'
		),
	oceanic: () =>
		import(
			'vue-style-loader?{"injectType":"styleTag","attributes":{"data-theme":"oceanic"}}!../../assets/themes/types/oceanic/oceanic.scss'
		),
	default: () =>
		import(
			'vue-style-loader?{"injectType":"styleTag","attributes":{"data-theme":"default"}}!../../assets/themes/default.scss'
		)
}

export function themeChanger() {
	return lazyThemeChanger(themes)
}
