export default function themeChanger(themes) {
	const getStyle = (theme) => {
		return document.querySelector(`link[href*="theme-${theme}"]:not([rel="prefetch"])`)
	}

	const toggleStyle = (style, state) => {
		if (state) {
			style.removeAttribute("media")
		} else {
			style.setAttribute("media", "max-width: 1px")
		}
	}

	const disableOthersStyles = (theme) => {
		const styles = document.querySelectorAll(`
            style[data-theme*=theme]:not([data-theme*=theme-${theme}]),
            link[href*=theme]:not([href*=theme-${theme}]):not([rel="prefetch"])
        `)
		styles.forEach((style) => toggleStyle(style, false))
	}

	const fetch = async (theme) => {
		const styles = new Set(document.querySelectorAll('style:not([url~=""])'))
		if (themes[theme]) await themes[theme]()
		let themeStyle = Array.from(document.querySelectorAll("style"))
			.filter((s) => !styles.has(s))
			.find((s) => s.innerText.includes(`/**@theme:${theme}*/`))
		if (themeStyle) {
			themeStyle.dataset.theme = `theme-${theme}`
		}
	}

	return async (theme) => {
		const themeStyle = getStyle(theme)
		if (themeStyle) {
			toggleStyle(themeStyle, true)
			disableOthersStyles(theme)
			return
		}
		await fetch(theme)
		disableOthersStyles(theme)
	}
}
