<template>
    <b-dropdown variant="link" class="bbb m-md-2" no-caret v-if="checkPermission">
        <div slot="button-content" class="theme-icon-wrapper">
            <themePickerIcon
                :hex1="activeThemeColors.pickerHex1"
                :hex2="activeThemeColors.pickerHex2"
                :hex3="activeThemeColors.pickerHex3"
            />
        </div>

        <div class="themes">
            <div
                v-for="item in themeModesFiltered"
                :key="item.key"
                class="theme"
                :class="{
                    opened: item.key === collapsedKey,
                    current: item.key == theme,
                    disabled: item.isDisabled
                }"
            >
                <span class="theme__line" :style="[item.key == theme ? { background: collapsedColor } : {}]"></span>
                <div class="theme__toggler" @click="collapse(item.key)">
                    <span class="theme__toggler-label">
                        {{ item.name }}
                    </span>
                    <i v-if="item.colors.length > 0" class="fa fa-chevron-down theme__toggler-icon" />
                </div>

                <div class="theme__body">
                    <div
                        v-for="color in item.colors"
                        :key="color.key"
                        @click="color.isDisabled ? null : changeTheme(item.key, color.key)"
                        class="theme__body-item"
                        :class="{
                            current: color.key == themeColor,
                            disabled: color.isDisabled
                        }"
                    >
                        <themePickerIcon :hex1="color.pickerHex1" :hex2="color.pickerHex2" :hex3="color.pickerHex3" />
                        <span
                            class="theme__body-itemframe"
                            :style="[color.key == themeColor ? { borderColor: collapsedColor } : {}]"
                        ></span>
                        <!-- <span :theme="color.key" class="theme__body-item"></span> -->
                    </div>
                </div>
            </div>
        </div>

        <b-dropdown-item @click="updateWinter">
            <b-form-checkbox size="md" class="pb-2" v-model="isWinter" :unchecked-value="true" :value="false">
                <span class="them-winter--title">{{ $l("platon.winter_mode", "Қишги режим") }}</span>
            </b-form-checkbox>
        </b-dropdown-item>
    </b-dropdown>
</template>
<script>
import { mapMutations, mapGetters } from "vuex"
import { themeChanger } from "@Platon/core/theme/theme-change"
import { hexColors, themeModes } from "@Platon/const"

import themePickerIcon from "./ThemePickerIcon.vue"

/**
*@typedef Color
*@property {string} key
*@property {string} name

* @typedef Theme
* @property name {string}
* @property key {string}
* @property colors {Color[]}

*/

export default {
    name: "ThemePicker",
    components: { themePickerIcon },

    data() {
        return {
            isWinter: false,
            collapsedKey: null
            // themeModes: themeModes
        }
    },

    created() {
        this.isWinter = JSON.parse(localStorage.getItem("platon_winter")) || false
    },

    computed: {
        ...mapGetters({
            theme: "platon/theme",
            themeColor: "platon/themeColor"
        }),

        collapsedColor() {
            return hexColors[this.activeThemeColors.key]
        },

        activeTheme() {
            return this.getTheme(this.theme)
        },

        activeThemeColors() {
            return this.getThemeColors(this.activeTheme, this.themeColor)
        },

        themeModesFiltered() {
            const disabledThemes = this.$projectInfo.theme?.themeDisabled ? this.$projectInfo.theme.themeDisabled : []
            if (!disabledThemes) return themeModes

            for (let i = 0; i < themeModes.length; i++) {
                const theme = themeModes[i]
                const disabledTheme = disabledThemes.find((disabledTheme) => disabledTheme.theme === theme.key)
                const { permissions } = this.$user
                if (disabledTheme) {
                    const hasColor = !!disabledTheme.colors
                    theme.colors.forEach((color) => {
                        let permission = `${theme.key}.${color.key}`
                        if (
                            (!hasColor || disabledTheme.colors.includes(color.key)) &&
                            !permissions.includes(`platon.enabledTheme.${permission}`) &&
                            !permissions.includes(`platon.activeTheme.${permission}`)
                        ) {
                            color.isDisabled = true
                        }
                    })
                }
            }
            return themeModes
        },

        checkPermission() {
            const permissions = this.$user?.permissions ?? []
            const permitStatus = permissions.some((el) => el.startsWith("platon.theme.hidden"))

            return !permitStatus
        }
    },

    methods: {
        ...mapMutations({
            setTheme: "platon/setTheme"
        }),

        /**
         *
         * @param {string} theme
         */
        getTheme(theme) {
            return themeModes.find((mode) => mode.key === theme) || themeModes[0]
        },

        /**
         * @param {object} theme
         * @param {string} themeColor
         */
        getThemeColors(theme, themeColor) {
            return theme.colors.find((color) => color.key === themeColor) || theme.colors[0]
        },

        updateWinter() {
            this.isWinter = !this.isWinter
            this.$sendEvent("platon_winter", this.isWinter)
            localStorage.setItem("platon_winter", this.isWinter)
        },

        /**
         * @param {Theme} theme
         * @param {Color} color
         */
        changeTheme(theme, color) {
            const change = themeChanger()
            change(theme)
            this.setTheme({ theme, color })
        },
        collapse(key) {
            if (key === this.collapsedKey) {
                this.collapsedKey = null
            } else {
                this.collapsedKey = key
            }
        }
    }
}
</script>
<style lang="scss">
.theme-icon-wrapper {
    width: 28px;
    height: 28px;
    filter: drop-shadow(0px 9px 4px rgba(0, 0, 0, 0.01)) drop-shadow(0px 5px 3px rgba(0, 0, 0, 0.05))
        drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.09)) drop-shadow(0px 1px 1px rgba(0, 0, 0, 0.1))
        drop-shadow(0px 0px 0px rgba(0, 0, 0, 0.1));

    svg {
        border-radius: 6px;
    }
}

.them-winter--title {
    font-size: 1rem;
    cursor: pointer;
}

.themes {
    min-width: 240px;
    user-select: none;
}

.theme {
    max-height: 50px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    transition: 0.3s linear;
    border-bottom: 1px solid #f2f4f5;
    position: relative;
    $root: &;
    &::-webkit-scrollbar {
        width: 0 !important;
        height: 0 !important;
    }

    &__toggler {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 10px 15px;
        height: 50px;
        flex-shrink: 0;
        cursor: pointer;

        &-label {
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 16px;
            color: var(--pl-text-color);
        }

        &-icon {
            font-size: 12px;
            transition: 0.3s;
        }
    }

    &__body {
        padding: 5px 15px 15px 15px;
        gap: 15px;
        display: grid;
        grid-template-columns: repeat(4, 1fr);

        &-item {
            width: 40px;
            height: 40px;
            display: flex;
            align-items: center;
            justify-content: center;
            //   background: #000;
            position: relative;
            border-radius: 10px;
            margin: auto;
            transition: 0.3s;
            cursor: pointer;
            filter: drop-shadow(0px 9px 4px rgba(0, 0, 0, 0.01)) drop-shadow(0px 5px 3px rgba(0, 0, 0, 0.05))
                drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.09)) drop-shadow(0px 1px 1px rgba(0, 0, 0, 0.1))
                drop-shadow(0px 0px 0px rgba(0, 0, 0, 0.1));

            &:hover {
                opacity: 0.6;
            }

            svg {
                width: 40px;
                height: 40px;
                overflow: hidden;
                border-radius: 10px;
            }
            &.disabled {
                pointer-events: none;
                opacity: 0.3;
            }
        }

        &-itemframe {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            z-index: 2;
            display: flex;
            width: calc(100% + 8px);
            height: calc(100% + 8px);
            border: 1px solid transparent;
            border-radius: 12px;
            transition: 0.3s;
        }
    }

    &__line {
        position: absolute;
        top: 8px;
        left: 0;
        bottom: 8px;
        width: 2px;
        display: flex;
        background: transparent;
        transition: 0.3s;
    }

    &:hover {
        background: #f8fbff;
        #{$root}__toggler-label {
            color: #000;
        }
    }

    &.opened {
        max-height: 300px;
        overflow: auto;

        .theme {
            &__toggler-icon {
                transform: rotate(180deg);
            }
        }
    }
}
</style>
