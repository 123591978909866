import { LAST_WINTER_DATE } from "@Platon/const"

export default {
	name: "WinterMixin",
	data() {
		return {
			isWinterDisabled: true
		}
	},
	computed: {
		isWinterSeason() {
			const currentDate = new Date()
			const winterDate = new Date(LAST_WINTER_DATE)
			winterDate.setFullYear(new Date().getFullYear())
			return winterDate.getTime() - currentDate.getTime() > 0
		}
	},
	mounted() {
		this.$onEvent("platon_winter", (value) => {
			this.isWinterDisabled = value
		})
	},

	created() {
		this.isWinterDisabled = JSON.parse(localStorage.getItem("platon_winter")) || !this.isWinterSeason
	}
}
