import { errorToast as ErrorToast, greenToast as GreenToast } from "@Platon/core/functions/toast"

export default {
	methods: {
		/**
		 * @param {string} title
		 * @param {string} text
		 */
		dangerToast(title, text) {
			this.$platonApp.$bvToast.toast(text, {
				title,
				appendToast: true,
				variant: "danger"
			})
		},

		/**
		 * @param {string} title
		 * @param {string} text
		 */
		successToast(title, text) {
			this.$platonApp.$bvToast.toast(text, {
				title,
				appendToast: true,
				variant: "success"
			})
		},

		/**
		 * @param {string} title
		 * @param {string} text
		 */
		infoToast(title, text) {
			this.$platonApp.$bvToast.toast(text, {
				title,
				appendToast: true,
				variant: "info"
			})
		},

		/**
		 * @param {string} text
		 */
		errorToast(text) {
			ErrorToast(text)
		},

		greenToast(text) {
			GreenToast(text)
		}
	}
}
