import PlatonPlugin from "@Platon/core/plugins/PlatonPlugin"
import PluginEvents from "@Platon/core/plugins/PluginEvents"
import { AUDIT_TYPES, AUDIT_URL } from "@Platon/const"

export default class AuditPlugin extends PlatonPlugin {
	load(core) {
		super.load(core)

		if (!AUDIT_URL) return

		window.$onEvent(this, "audit:action", this.OnCustomAction.bind(this))

		core.registerEvent(PluginEvents.AfterLogin, this.AfterLogin.bind(this))
		core.registerEvent(PluginEvents.AfterLogout, this.AfterLogout.bind(this))
		core.registerEvent(PluginEvents.OnPageOpened, this.OnPageOpened.bind(this))
		core.registerEvent(PluginEvents.OnTableOpened, this.OnTableOpened.bind(this))
		core.registerEvent(PluginEvents.OnFormRead, this.OnFormRead.bind(this))
		core.registerEvent(PluginEvents.OnFormInsert, this.OnFormInsert.bind(this))
		core.registerEvent(PluginEvents.OnFormUpdate, this.OnFormUpdate.bind(this))
		core.registerEvent(PluginEvents.OnFormDelete, this.OnFormDelete.bind(this))
		core.registerEvent(PluginEvents.OnFormHardDelete, this.OnFormHardDelete.bind(this))
	}

	/**
	 * @param {PlatonPluginCore} core
	 * @param {User} user
	 */
	async AfterLogin(core, user) {
		await this.auditUserAction(AUDIT_TYPES.loggedIn)
	}

	/**
	 * @param {PlatonPluginCore} core
	 * @param {User} user
	 */
	async AfterLogout(core, user) {
		await this.auditUserAction(AUDIT_TYPES.loggedOut)
	}

	/**
	 * @param {PlatonPluginCore} core
	 */
	async OnPageOpened(core, details) {
		let detailsObject = {
			viewingPage: details.data.name,
			isPagePublic: details.data.isPublic,
			queryParams: details.queryParams
		}
		await this.auditUserAction(AUDIT_TYPES.pageRead, detailsObject)
	}

	/**
	 * @param {PlatonPluginCore} core
	 */
	async OnTableOpened(core, details) {
		let detailsObject = {
			viewingTable: details.params.name,
			isPublicTable: details.meta["publicRoute"] === true,
			queryParams: details.query
		}
		await this.auditUserAction(AUDIT_TYPES.tableRead, detailsObject)
	}

	/**
	 * @param {PlatonPluginCore} core
	 */
	async OnFormRead(core, details) {
		await this.auditUserAction(AUDIT_TYPES.formRead, details)
	}

	/**
	 * @param {PlatonPluginCore} core
	 */
	async OnFormInsert(core, details) {
		await this.auditUserAction(AUDIT_TYPES.formInsert, details)
	}

	/**
	 * @param {PlatonPluginCore} core
	 */
	async OnFormUpdate(core, details) {
		await this.auditUserAction(AUDIT_TYPES.formUpdate, details)
	}

	/**
	 * @param {PlatonPluginCore} core
	 */
	async OnFormDelete(core, details) {
		await this.auditUserAction(AUDIT_TYPES.formDelete, details)
	}

	/**
	 * @param {PlatonPluginCore} core
	 */
	async OnFormHardDelete(core, details) {
		await this.auditUserAction(AUDIT_TYPES.formHardDelete, details)
	}

	/**
	 * @param {string} auditCustomTitle
	 * @param {string} auditCustomType
	 * @param {Record<string, string>} details
	 */
	async OnCustomAction(auditCustomTitle, auditCustomType, details) {
		let data = {
			auditCustomType,
			...details
		}

		await this.auditUserAction(auditCustomTitle, data)
	}

	/**
	 * @param {string} actionTitle
	 * @param {Record<string, string>} details
	 */
	async auditUserAction(actionTitle, details = {}) {
		let user = this.core.store.state.platon.user
		let userId = user ? user.id : null
		let actionType = details.auditCustomType
			? details.auditCustomType
			: Object.keys(AUDIT_TYPES).find((key) => AUDIT_TYPES[key] === actionTitle)

		let data = {
			browser: {
				appCodeName: navigator.appCodeName,
				appName: navigator.appName,
				appVersion: navigator.appVersion,
				language: navigator.language,
				platform: navigator.platform,
				userAgent: navigator.userAgent,
				vendor: navigator.vendor,
				userAgentData: navigator.userAgentData
			},
			fullTime: moment().toISOString(true),
			fullUrl: window.location.href,
			userId,
			user,
			actionTitle,
			actionType,
			details
		}

		await window.$api.post(AUDIT_URL, data)
	}
}
