import AlertDialog from "@Platon/components/extended/AlertDialog.vue"
import MessageDialog from "@Platon/components/extended/MessageDialog.vue"

export default {
	methods: {
		/**
		 * @param title
		 * @param text
		 * @param {Object} options
		 * @param {string?} options.title
		 * @param {string?} options.text
		 * @param {string?} options.confirmIcon
		 * @param {string?} options.rejectIcon
		 * @param {string?} options.confirmText
		 * @param {string?} options.rejectText
		 * @param {object?} options.modalProps
		 * @param {Function?} options.onReject
		 * @return {Promise<unknown>}
		 */
		showConfirmDialog(title, text, options = {}) {
			return new Promise((resolve, reject) => {
				this.$modal.show(
					AlertDialog,
					{
						title,
						text,
						options,
						callback: (evt) => {
							if (evt === "confirmed") {
								resolve()
							} else {
								reject()
							}
						}
					},
					{ height: "auto", width: "350px", ...options }
				)
			})
		},

		/**
		 * @param fn
		 * @param options
		 * @param {string?} options.title
		 * @param {string?} options.text
		 * @param {string?} options.confirmText
		 * @param {string?} options.rejectText
		 * @param {string?} options.confirmIcon
		 * @param {string?} options.rejectIcon
		 * @param {object?} options.modalProps
		 * @param {Function?} options.onReject
		 */
		confirmAction(fn, options = {}) {
			return new Promise((resolve, reject) => {
				this.showConfirmDialog(
					options.title || this.$l("platon.attention", "Диққат"),
					options.text || this.$l("platon.confirm_action", "Амал бажаришни тасдиқлайсизми?"),
					options
				)
					.then(() => {
						fn()
						resolve()
					})
					.catch(() => {
						options.onReject && options.onReject()

						reject()
					})
			})
		},

		messageBox(text, options) {
			this.$modal.show(
				MessageDialog,
				{
					title: options.title,
					text,
					actions: options.actions,
					options
				},
				{ height: "auto", width: "350px", ...options }
			)
		},

		successMessageBox(text, options = {}) {
			this.messageBox(text, Object.assign(options, { headerClass: "bg-success" }))
		},

		errorMessageBox(text, options = {}) {
			this.messageBox(text, Object.assign(options, { headerClass: "bg-danger" }))
		},

		infoMessageBox(text, options = {}) {
			this.messageBox(text, Object.assign(options, { headerClass: "bg-info" }))
		},

		warningMessageBox(text, options = {}) {
			this.messageBox(text, Object.assign(options, { headerClass: "bg-warning" }))
		},

		/**
		 * @param title
		 * @param text
		 * @param {Object} options
		 * @param {Vue.Component} options.component
		 * @param {Object?} options.componentProps
		 * @param {string?} options.confirmIcon
		 * @param {string?} options.rejectIcon
		 * @param {string?} options.confirmText
		 * @param {string?} options.rejectText
		 * @param {string?} options.inputTag
		 * @param {Function?} options.onReject
		 * @return {Promise<unknown>}
		 */
		async promptAction(title = undefined, text = undefined, options = {}) {
			title = title || this.$l("platon.attention", "Диққат")
			text = text || this.$l("platon.fill_input", "Киритинг")

			const component = options.component || {
				data() {
					return {
						tag: options.inputTag || "input",
						inputVal: "",
						text
					}
				},
				template: `
					<div>
					<div style="font-size: 16px" v-html="text"/>
					<input v-if="tag === 'input'" class="form-control" v-model="inputVal"/>
					<textarea v-else-if="tag === 'textarea'" class="form-control" v-model="inputVal"/>
					<component v-else :is="tag" class="form-control" v-model="inputVal"/>
					</div>`
			}

			return new Promise((resolve, reject) => {
				this.$modal.show(
					AlertDialog,
					{
						title,
						options,
						component,
						componentProps: options.componentProps,

						callback: (evt, modal) => {
							if (evt === "confirmed") {
								resolve(modal.$refs.component.$data.inputVal)
							} else {
								reject()
							}
						}
					},
					{ height: "auto", width: "350px", ...options }
				)
			})
		}
	}
}
