<template>
    <p-modal :footer-actions="actions" :can-cancel="!isPasswordRequired">
        <template #title>
            {{ $l("platon.social.set-password", "Тизимга кириш учун парол белгиланг") }}
        </template>

        <form @submit.prevent="onSubmit">
            <password-control
                v-model="$v.password.$model"
                :label="$l('platon.password', 'Парол')"
                :placeholder="$l('platon.password', 'Парол')"
                autocomplete="current-password"
                type="password"
                :configurable="false"
                class="p-0 mb-0"
                :required="data.password_policy === 'required'"
                :class="{ invalid: $v.password.$dirty && !$v.password.required }"
            />

            <small class="text-danger" v-if="$v.password.$dirty && !$v.password.required">
                {{ $l("platon.enterPassword", "Паролни киритинг") }}
            </small>
            <small class="text-danger" v-if="$v.password.$dirty && !$v.password.minLength">
                {{ $l("platon.password_min_length", "Парол 6та дан кам бўлмаслиги керак") }}
            </small>
        </form>
    </p-modal>
</template>
<script>
import PasswordControl from "@Platon/components/form/controls/PasswordControl"
import { minLength, required } from "vuelidate/lib/validators"

export default {
    name: "SocialLoginModal",
    components: { PasswordControl },
    props: {
        data: {},
        method: "",
        accessToken: "",
        login: () => {}
    },

    data() {
        return {
            password: ""
        }
    },

    validations() {
        if (this.isPasswordRequired) {
            return {
                password: { required, minLength: minLength(6) }
            }
        } else {
            return {
                password: { minLength: minLength(6) }
            }
        }
    },

    computed: {
        isPasswordRequired() {
            return this.data.password_policy === "required"
        },

        /**
         * @returns {ModalAction[]}
         */
        actions() {
            const actions = []

            actions.push({ text: this.$l("platon.save", "Сақлаш"), handler: this.onSubmit })

            if (!this.isPasswordRequired) {
                actions.push({
                    text: this.$l("platon.social.skip-password", "Ўтказиб юбориш"),
                    handler: this.closeModal
                })
            }

            return actions
        }
    },

    methods: {
        onSubmit(e) {
            this.$v.$touch()

            if (this.$v.$invalid) {
                return true
            }

            const { accessToken, password, method } = this

            this.login({
                method,
                accessToken,
                closeModal: this.closeModal,
                password: password ? password : undefined
            })
        },

        closeModal() {
            this.$emit("close")
        }
    }
}
</script>
