import { or, parseValue } from "@Platon/core/helpers"

export default {
	props: {
		value: {},
		defaultValue: {}
	},

	data() {
		let local = parseValue(or(this.value, or(this.defaultValue, null)), this.parseAs)

		return {
			local
		}
	},

	mounted() {
		// this.updateLocal(parseValue(or(this.value, or(this.defaultValue, null)), this.parseAs))
	},

	computed: {
		parseAs() {
			return "default"
		}
	},

	methods: {
		updateLocal(value) {
			if (value != this.local) {
				this.local = value
				this.$emit("input", value)
			}
		}
	},
	watch: {
		value(val, old) {
			this.local = val
		}
	}
}
