<template>
    <div class="PasswordSettings">
        <FormPassword label="Жорий пароль" name="prevPassword" @onchange="handleChangePasswordInput" />
        <FormPassword label="Янги пароль" name="newPassword" @onchange="handleChangePasswordInput" />
        <ActionButton :isLoading="loading" className="PasswordSettings__actionButton" @onclick="postPasswordChanges">
            Узгартириш
        </ActionButton>
    </div>
</template>

<script>
import ActionButton from "../components/actionButton"
import FormPassword from "../components/formPassword"
import ToastMixin from "@Platon/mixins/ToastMixin"
import { errorToast, successToast } from "@Platon/core/functions/toast"
import { API_BASE_URL } from "@Platon/const"

export default {
    name: "PasswordSection",
    components: { FormPassword, ActionButton },
    mixins: [ToastMixin],
    data() {
        return {
            loading: false,
            prevPassword: "",
            newPassword: ""
        }
    },
    methods: {
        handleChangePasswordInput(input, value) {
            this[input] = value
        },
        async postPasswordChanges() {
            this.loading = true
            try {
                await this.$api.post(`${API_BASE_URL}auth/change_user_password`, {
                    oldPassword: this.prevPassword,
                    newPassword: this.newPassword
                })
                this.greenToast(this.$l("platon.form_saved", "Маълумотлар сақланди"))
                this.loading = false
                this.prevPassword = ""
                this.newPassword = ""
            } catch (e) {
                this.loading = false
                this.errorToast(this.$l("platon.form_server_error", "Серверда хатолик"))
            } finally {
                this.loading = false
            }
        }
    }
}
</script>

<style lang="scss" scoped>
@import "../components/utils/mixins.scss";

.PasswordSettings {
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
    width: 100%;

    & .form-control-profile-view {
        width: 35%;
        margin-bottom: 0;

        &:nth-child(2) {
            margin-left: 1rem;
        }
    }

    &__actionButton {
        margin-left: 1rem;
    }
}
</style>
