<template>
    <div class="copyable--wrapper" @click="copyTextToClipboard">
        <slot></slot>
    </div>
</template>

<script>
export default {
    props: {
        text: String
    },
    data() {
        return {
            hasSavedSuccessfully: false
        }
    },
    methods: {
        copyTextToClipboard() {
            let text = this.text
            if (navigator.clipboard) {
                navigator.clipboard.writeText(text)
                this.$platonApp.greenToast("Кўчирилди")
            }
        }
    }
}
</script>

<style>
.copyable--wrapper {
    cursor: pointer;
    position: relative;
}
</style>
