<template>
    <div class="imagePreview__holder">
        <img class="imagePreview" :src="url" />
    </div>
</template>

<script>
export default {
    name: "ImagePreview",

    props: {
        url: {
            required: true,
            type: String
        }
    }
}
</script>

<style lang="scss" scoped>
.imagePreview {
    max-width: 100%;
    max-height: 70vh;
    object-fit: contain;

    &__holder {
        background: #333333;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}
</style>
