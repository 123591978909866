export default {
	AfterAuth: "AfterAuth",
	BeforeAuth: "BeforeAuth",

	BeforeLogin: "BeforeLogin",
	AfterLogin: "AfterLogin",
	SetUserTheme: "SetUserTheme",

	AfterLogout: "AfterLogout",
	OnPageOpened: "OnPageOpened",
	OnTableOpened: "OnTableOpened",
	OnFormRead: "OnFormRead",
	OnFormInsert: "OnFormInsert",
	OnFormUpdate: "OnFormUpdate",
	OnFormDelete: "OnFormDelete",
	OnFormHardDelete: "OnFormHardDelete",

	AfterInit: "AfterInit"
}
