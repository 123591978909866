export const EventNames = {
	FORM_SAVED: "formSaved",
	RELOAD_MENU: "reloadMenu"
}

export default {
	methods: {
		/**
		 * @param {Window} win
		 * @param {?string} formName
		 * @param payload
		 */
		publishSaveFormEvent(win, formName = null, payload = {}) {
			this.publishNativeEvent(win, EventNames.FORM_SAVED, payload)

			if (formName === "menus") {
				this.publishReloadMenu(win)
			}
		},

		publishReloadMenu(win) {
			this.publishNativeEvent(win, EventNames.RELOAD_MENU)
		},

		/**
		 * @param {Window} win
		 * @param {string} eventName
		 * @param {Object} payload
		 */
		publishNativeEvent(win, eventName, payload = {}) {
			let event = new CustomEvent(eventName, {
				detail: payload
			})

			try {
				win.dispatchEvent(event)
			} catch {
				// we don't care
			}
		}
	}
}
