<template>
    <BaseControl v-bind="$attrs" class="position-relative" :errors="combinedErrors">
        <p-datepicker
            ref="input"
            :placeholder="_placeholder"
            :value="local"
            @input="(date) => updateLocal(date)"
            :config="options"
            :class="classes"
            class="date-picker-input"
            @dp-show="onPickerShow"
            :readonly="readonly"
            :disabled="disabled"
        >
        </p-datepicker>

        <div class="icon position-absolute" @click="focusField()">
            <i class="fa fa-calendar-alt" />
        </div>
    </BaseControl>
</template>

<script>
import BaseControl from "@Platon/components/form/controls/BaseControl.vue"
import InputControlMixin from "@Platon/mixins/InputControlMixin"
import DatepickerMixin from "@Platon/components/form/controls/DatepickerMixin"
import ValidationMixin from "@Platon/mixins/ValidationMixin"
import { DEFAULT_DATETIME_FORMAT, DEFAULT_TIME_FORMAT } from "@Platon/const"
import moment from "moment"
import "@Platon/external/datepicker/lang-la.js"
import { localeInfo } from "@Platon/core/translations"

export default {
    name: "TimeControl",

    components: {
        BaseControl
    },

    mixins: [InputControlMixin, DatepickerMixin, ValidationMixin],

    props: {},

    computed: {
        options() {
            let extra = {}

            if (this.item.minDate) {
                extra.minDate = moment(this.item.minDate, DEFAULT_DATETIME_FORMAT)
            }

            if (this.item.maxDate) {
                extra.maxDate = moment(this.item.maxDate, DEFAULT_DATETIME_FORMAT)
            }

            return {
                format: this.dateFormat,
                locale: localeInfo.locale,
                useCurrent: false,
                showClear: true,
                showClose: true,
                ...extra
            }
        },

        dateFormat() {
            return this.item.format || DEFAULT_TIME_FORMAT
        },

        classes() {
            return [`text-${this.item.textAlign || "left"}`]
        },

        _placeholder() {
            return this.$props.placeholder || this.item.placeholder || this.dateFormat
        }
    }
}
</script>

<style scoped>
.icon {
    right: 1rem;
    bottom: 0.5rem;
}
</style>
