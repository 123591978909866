import LoadUserOrganizationsAfterLogin from "@Platon/platon/plugins/LoadUserOrganizationsAfterLogin"
import PickOrganizationAfterAuth from "@Platon/platon/plugins/PickOrganizationAfterAuth"
import PlatonSentryPlugin from "@Platon/platon/plugins/PlatonSentryPlugin"
import SetLocaleOfUserAfterAuth from "@Platon/platon/plugins/SetLocaleOfUserAfterAuth"
import SetUserThemeAfterLogin from "@Platon/platon/plugins/SetUserThemeAfterLogin"
import AuditPlugin from "@Platon/platon/plugins/AuditPlugin"

/**
 * @type {(PlatonPlugin|PlatonPluginRegisterOptions)[]}
 */
const plugins = [
	{ priority: 0, plugin: PlatonSentryPlugin },
	{ priority: 45, plugin: AuditPlugin },
	{ priority: 50, plugin: SetLocaleOfUserAfterAuth },
	{ priority: 100, plugin: LoadUserOrganizationsAfterLogin },
	{ priority: 200, plugin: PickOrganizationAfterAuth },
	{ priority: 300, plugin: SetUserThemeAfterLogin }
]

export default plugins
