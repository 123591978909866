export default {
	data() {
		return {
			isPublicPlatonComponent: this.$route.meta["publicRoute"] === true
		}
	},

	methods: {
		addPublicRoutePrefix(route) {
			return this.isPublicPlatonComponent ? "public/" + route : route
		}
	}
}
