<template>
    <b-form-group :class="classes" :style="styles" class="position-relative over-hover align-self-end">
        <div
            v-if="canControl || (labels.top && !disableLabel)"
            class="d-flex"
            :style="{ 'min-height: 22px;': !isFilterElement }"
        >
            <label v-if="labels.top && !disableLabel" class="flex-grow-1 mb-0">
                <span v-html="labels.top" class="mr-1" />
                <span class="text-danger mr-1" v-if="isRequired('top')" v-text="'*'" />
                <i
                    class="fa fa-info-circle text-secondary"
                    v-b-tooltip.html="hint"
                    v-if="hintPosition === 'label' && hint"
                ></i>
            </label>

            <slot name="top-right"> </slot>

            <PlatonLink
                v-if="canControl"
                style="right: 8px; z-index: 5"
                class="small over-target ml-2"
                tabindex="-1"
                :link="manageLink"
            >
                <i class="fa fa-cog"></i>
            </PlatonLink>
        </div>

        <div class="d-flex align-items-center">
            <label class="text-nowrap float-right pr-1" v-if="!disableLabel && labels.left" v-html="labels.left" />
            <span class="text-danger mr-1" v-if="isRequired('left')" v-text="'*'" />

            <component
                :is="appendElementsLength || prependElementsLength ? 'b-input-group' : 'div'"
                style="width: 100%"
                class="position-relative"
                :class="slotClass"
            >
                <template v-if="appendElementsLength" #append>
                    <PlatonFormElement
                        class="cstm-addon right"
                        element-type="form_elements"
                        :item="e"
                        :key="e.id"
                        v-for="e in appendElements"
                    />
                </template>
                <template v-if="prependElementsLength" #prepend>
                    <PlatonFormElement
                        class="cstm-addon left"
                        element-type="form_elements"
                        :item="e"
                        :key="e.id"
                        v-for="e in prependElements"
                    />
                </template>

                <slot></slot>
                <i
                    class="ml-2 fa fa-info-circle text-secondary small"
                    v-b-tooltip.html="hint"
                    v-if="hintPosition === 'after' && hint"
                ></i>
            </component>

            <label class="text-nowrap pl-1" v-if="!disableLabel && labels.right" v-html="labels.right" />
            <span class="text-danger" v-if="isRequired('right')" v-text="'*'" />
        </div>

        <label v-if="!disableLabel && labels.bottom">{{ labels.bottom }}</label>
        <span class="text-danger" v-if="isRequired('bottom')" v-text="'*'" />

        <div v-if="errors.length > 0" class="form-control-error" data-error-list="">
            <ul>
                <li :key="i" v-for="(e, i) in errors">{{ e }}</li>
            </ul>
        </div>
    </b-form-group>
</template>

<script>
import PermissionMixin from "@Platon/mixins/table/PermissionMixin"
import PlatonLink from "@Platon/components/extended/PlatonLink.vue"

export default {
    name: "BaseControl",
    components: { PlatonLink, PlatonFormElement: () => import("@Platon/components/form/PlatonFormElement.vue") },
    mixins: [PermissionMixin],

    props: {
        size: {
            default: "12"
        },
        label: {
            default: "Label"
        },

        disableLabel: {
            default: false
        },

        configurable: {
            default: true
        },

        errors: {
            type: Array,
            default: () => []
        },

        hasPrepend: {
            default: false,
            type: Boolean
        },

        hasAppend: {
            default: false,
            type: Boolean
        },

        appendElements: {
            default: () => [],
            type: Array
        },

        prependElements: {
            default: () => [],
            type: Array
        },

        slotClass: {
            type: String
        },

        hintPosition: {
            default: "label",
            type: String
        }
    },

    methods: {
        isRequired(pos) {
            return this.$attrs.required && this.renderRedDot === pos
        }
    },

    inject: {
        table: {
            default: undefined
        },

        form: {
            default: undefined
        }
    },

    computed: {
        manageLink() {
            if (this.canControl) {
                if (this.$attrs["element-type"] === "table_filters" && this.table) {
                    return (
                        `/forms/${this.$attrs["element-type"]}/${this.$attrs["element-id"]}?` +
                        `_target=modal&table_name=${this.table.tableName}&table_level=${this.table.tableLevel}`
                    )
                } else if (this.$attrs["element-type"] === "form_elements" && this.form && this.form.formMeta) {
                    return (
                        `/forms/${this.$attrs["element-type"]}/${this.$attrs["element-id"]}?` +
                        `_target=modal&form_name=${this.form.formMeta.name}`
                    )
                }
            }

            return null
        },

        canControl() {
            return this.isAdmin && this.configurable && this.isMyProject(this.$parent.item)
        },

        classes() {
            let cls = []

            if (this.size <= 12) {
                cls.push("col-md-" + this.size)
            } else {
                cls.push("col-md-2")
            }

            if (this.isFilterElement) cls.push("my-1")

            return cls
        },

        styles() {
            let styles = {}

            if (this.size > 12 && this.size <= 100) {
                styles["maxWidth"] = `${this.size}%`
                styles["minWidth"] = `${this.size}%`
            } else if (this.size > 100) {
                styles["maxWidth"] = `${this.size}px`
                styles["minWidth"] = `${this.size}px`
            }

            return styles
        },

        isFilterElement() {
            return this.$attrs["element-type"] === "table_filters"
        },

        labels() {
            let labels = (this.label || "").split("|")

            return {
                top: labels[0],
                left: labels[1],
                right: labels[2],
                bottom: labels[3]
            }
        },

        renderRedDot() {
            if (this.labels.top) return "top"
            else if (this.labels.left) return "left"
            else if (this.labels.right) return "right"
            else if (this.labels.bottom) return "bottom"
        },

        hint() {
            return this.$parent.item && this.$parent.item.hint
        },

        appendElementsLength() {
            return this.appendElements.length
        },

        prependElementsLength() {
            return this.prependElements.length
        }
    }
}
</script>

<style lang="scss">
.cstm-addon {
    all: unset;
    margin-bottom: 0 !important;

    &.right:last-child {
        button {
            border-radius: 0 0.25rem 0.25rem 0;
        }
    }

    &.left:first-child {
        button {
            border-radius: 0.25rem 0 0 0.25rem;
        }
    }

    &.right:nth-last-child(n + 2) {
        button {
            border-radius: 0;
            border-right-color: var(--pl-btn-primary-hover) !important;
        }
    }

    &.left:nth-child(n + 2) {
        button {
            border-radius: 0;
            border-left-color: var(--pl-btn-primary-hover) !important;
        }
    }

    .btn {
        transition: 0.3s;
        display: flex;
    }

    a.link {
        display: none;
    }
}

.input-group:has(.input-group-append),
.input-group:has(.input-group-prepend) {
    flex-wrap: nowrap;
}

.input-group:has(.input-group-prepend) {
    .vue-simple-suggest.designed .input-wrapper input {
        border-radius: 0 5px 5px 0;
    }
}

.input-group:has(.input-group-append) {
    .vue-simple-suggest.designed .input-wrapper input {
        border-radius: 5px 0 0 5px;
    }
}

.input-group:has(.input-group-append):has(.input-group-prepend) {
    .vue-simple-suggest.designed .input-wrapper input {
        border-radius: 0;
    }
}
</style>
