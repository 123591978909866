<template>
    <div class="platon-profile d-flex align-items-center" style="min-height: 70px" v-if="user">
        <div class="platon-user-photo mr-2">
            <b-avatar :src="$platonFile(user.photo)" size="48px" :text="avatarText" variant="primary" />
        </div>
        <div class="platon-user-info">
            <div class="font-weight-bold">{{ user.fullName || user.username }}</div>
            <div v-if="user.postName" class="small">{{ user.postName }}</div>
        </div>
        <div
            v-if="Array.isArray(organizations) && organizations.length > 0"
            @click="showOrganization"
            class="cursor-pointer"
        >
            <i class="fa fa-chevron-down"></i>
        </div>
    </div>
</template>
<script>
import { mapGetters } from "vuex"
import { pickUserOrganization } from "@Platon/mixins/pickUserOrganization"

export default {
    name: "LeftMenuProfile",

    computed: {
        ...mapGetters({
            /** @type User */
            user: "platon/user"
        }),

        avatarText() {
            try {
                return this.user.username[0]
            } catch {
                return "A"
            }
        },

        organizations() {
            return this.user.organizations
        }
    },

    methods: {
        showOrganization() {
            pickUserOrganization(this.$root, this.user.organizations, {
                closeable: true
            }).then((orgId) => {
                location.reload()
            })
        }
    }
}
</script>
