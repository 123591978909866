<template>
    <div id="Plt-ProfileViewAccordion" :class="`ProfileViewAccordion ${disabled && 'disabled-accordion'}`">
        <!-- ${open ? 'accordion-is-open' : 'accordion-is-close'} PltAccordion${useId} ${
            disabled && 'disabled-accordion'
        }  -->
        <div @click="toggleAccordionOption" class="ProfileViewAccordion__topbar">
            <div class="ProfileViewAccordion__topbar--header">
                <div class="topbar-header">
                    <i :class="`fa ${icon}`" />
                </div>
                <h3 class="topbar-typography">
                    {{ header }}
                </h3>
            </div>
            <button
                :class="`ProfileViewAccordion__topbar--action topbar-action-button ${open ? 'rotate180' : 'rotate0'}`"
            >
                <i class="mdi mdi-chevron-down" />
            </button>
        </div>
        <b-collapse :id="`collapse${this.useId}`" v-model="open" class="ProfileViewAccordion__frame">
            <slot />
        </b-collapse>
        <!-- <div
            id="Plt-Accordion-Frame"
            :class="`ProfileViewAccordion__frame ${open ? 'frame-is-open' : 'frame-is-close'} PltFrame${useId}`"
        >
            <slot />
        </div> -->
    </div>
</template>

<script>
import { makeAnId } from "../utils"

export default {
    name: "accordionComponent",
    props: {
        active: {
            type: Boolean,
            default: false
        },
        icon: {
            type: String,
            default: ""
        },
        header: {
            type: String,
            default: ""
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            open: false,
            defaultHeightSize: 65,
            useId: makeAnId()
        }
    },
    methods: {
        toggleAccordionOption() {
            if (this.disabled) return
            this.open = !this.open
            // const accordion = document.querySelector(".PltAccordion" + this.useId)
            // const accordionSlot = document.querySelector(".PltFrame" + this.useId)
            // if (this.open)
            //     accordion.style.height = `calc(${this.defaultHeightSize}px + ${accordionSlot.clientHeight}px)`
            // else accordion.style.height = `${this.defaultHeightSize}px`
        }
    },
    mounted() {
        if (this.active) this.open = true
        else this.open = false
    }
}
</script>

<style lang="scss" scoped>
@import "./utils/mixins.scss";

.ProfileViewAccordion {
    border-top: 1px solid $df-border-color;
    transition: all 0.2s ease;
    background-color: #fff;

    &.disabled-accordion {
        background-color: $df-border-color;
        border-radius: $df-border-radius;
    }

    &.accordion-is-open {
    }
    &.accordion-is-close {
    }

    &__topbar {
        width: 100%;
        cursor: pointer;
        padding: 10px 20px;
        @include display-flex;
        justify-content: space-between;

        &--header {
            @include display-flex;

            & .topbar-header {
                padding: 5px;
                border-radius: $df-border-radius;
                width: calc($df-size);
                height: calc($df-size);
                background-color: $df-icon-color;
                @include display-flex-center;

                & i {
                    color: #fff;
                    font-size: calc($df-size-small + 1px);
                }
            }

            & .topbar-typography {
                color: $df-color;
                font-weight: $df-w-bold;
                font-size: calc($df-size-small + 2px);
                margin-bottom: 0;
                margin-left: 12px;
            }
        }

        &--action {
            background-color: transparent;
            border: 0;
            outline: none;
            border-radius: 50%;
            width: calc($df-size + 17px);
            height: calc($df-size + 17px);

            @include display-flex-center;

            & i {
                color: $df-icon-color;
                font-size: $df-size;
                height: 100%;

                &::before {
                    height: 100%;
                    margin-top: 1px;
                }
            }

            transition: all 0.15s ease;
            &:hover {
                background-color: rgba($color: $df-color, $alpha: 0.1);
            }

            &.rotate180 {
                animation: rotate180IconButton 0.2s ease;
                transform: rotate(180deg);
            }

            &.rotate0 {
                animation: rotate0IconButton 0.2s ease;
                transform: rotate(0deg);
            }
        }
    }

    &__frame {
        // opacity: 0;
        // visibility: hidden;
        padding: 10px;
        // transition: all 0.12s ease;
        transition: all 0.15s ease-out;

        &.frame-is-close {
            opacity: 0;
            visibility: hidden;
        }
        &.frame-is-open {
            opacity: 1;
            visibility: visible;
        }
    }
}
</style>
