<template>
    <div class="AuthWithMFA">
        <div class="AuthWithMFA__verification">
            <h3 :class="`${state.isError && 'error'} ${state.verified ? 'succeeded' : ''}`">
                {{ $l("platon.enter-6-code", "Иловадан 6 хонали кодни киритинг") }}
            </h3>
            <input
                ref="inputRef"
                maxlength="6"
                :value="filteredValue"
                @input="verifyAuthorizationViaMFA"
                @keyup.enter="submitAuthorizationDigits"
                :placeholder="$l('platon.enter-6-code-placeholder', 'Кодни киритинг')"
                :class="`AuthorizationWithMFA ${state.isError ? 'error' : ''}  ${state.verified ? 'succeeded' : ''}`"
            />
        </div>
    </div>
</template>

<script>
export default {
    name: "AuthorizationWithMFA",
    props: {
        state: {
            default: function () {
                return {
                    verified: false,
                    isError: false
                }
            }
        }
    },
    data() {
        return {
            verificationModel: "",
            maxLength: 6
        }
    },
    computed: {
        filteredValue() {
            return this.verificationModel.replace(/\D/g, "")
        }
    },
    methods: {
        verifyAuthorizationViaMFA(event) {
            this.verificationModel = event.target.value
            this.$emit("authorization", this.verificationModel)
        },
        submitAuthorizationDigits() {
            this.$emit("authorizationSubmit")
        }
    },
    watch: {
        "state.isError": function () {
            if (this.state.isError && this.$refs.inputRef) {
                const otpCodeInput = this.$refs.inputRef
                otpCodeInput.focus()
            }
        }
    }
}
</script>

<style lang="scss">
@import "../../components/utils/mixins";

.AuthWithMFA {
    width: $df-panel-width;

    &__verification {
        height: 100%;
        width: 100%;
        @include display-flex-center;
        flex-direction: column;

        & h3 {
            color: $df-color;
            font-size: $df-middle;
            font-weight: $df-w-lighter;

            &.error {
                color: $df-secondary;
            }
            &.succeeded {
                color: $df-success;
            }
        }

        & input {
            width: 325px;
            height: 50px;
            color: $df-color-primary;
            text-align: center;
            margin-top: 10px;
            outline: none;
            font-size: $df-middle;
            border-radius: $df-border-radius;
            transition: all 0.15s ease;
            border: 2px solid rgba($color: $df-color-primary, $alpha: 0.8);

            &:active,
            &:focus {
                border-color: $df-color-primary;
            }

            &::-webkit-input-placeholder {
                color: $df-color-primary !important;
            }

            &.succeeded {
                color: $df-success;
                border-color: $df-success;
                &::-webkit-input-placeholder {
                    color: $df-success !important;
                }
            }
            &.error {
                color: $df-secondary;
                border-color: $df-secondary;
                &::-webkit-input-placeholder {
                    color: $df-secondary !important;
                }
            }
        }
    }
}
</style>
