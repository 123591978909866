<template>
    <div class="text-center d-flex align-items-center justify-content-center flex-column">
        <div><i style="font-size: 60px" class="mb-4" :class="icon" /></div>

        <div class="h3" v-if="endpoint.status >= 500">
            {{ $l("platon.server_error", "Серверда хатолик") }}

            <div>
                <btn
                    variant="primary"
                    class="mt-3 btn-lg"
                    icon="fa fa-refresh"
                    :loading="endpoint.loading"
                    @click.native="endpoint.fetch()"
                >
                    {{ $l("platon.reload_page", "Сахифани қайта юклаш") }}
                </btn>
            </div>
        </div>

        <div class="h3 d-flex flex-column" v-else-if="endpoint.status === 403 || endpoint.status === 401">
            {{ accessDeniedText }}

            <div>
                <router-link class="mt-3 btn btn-lg btn-primary" :to="'/'">
                    {{ $l("platon.back_to_home", "Бош саҳифага қайтиш") }}
                </router-link>
            </div>
        </div>

        <div class="h3 d-flex flex-column" v-else>
            {{ notFoundText }}

            <div>
                <router-link class="mt-3 btn btn-lg btn-primary" :to="'/'">
                    {{ $l("platon.back_to_home", "Бош саҳифага қайтиш") }}
                </router-link>
            </div>
        </div>
    </div>
</template>

<script>
import Btn from "@Platon/components/extended/Btn.vue"

export default {
    name: "ChimeraErrorsView",
    components: { Btn },
    props: ["endpoint", "icon", "accessDeniedText", "notFoundText"]
}
</script>
