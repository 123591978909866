<template>
    <div class="topbar primary-text">
        <slot name="before-title"></slot>

        <slot name="after-title"></slot>

        <component v-if="topbarLeft" :is="topbarLeft" />

        <portal-target name="topbar-filters" class="flex-grow-1" multiple />

        <slot name="before-lang"></slot>

        <component v-if="topbarRight" :is="topbarRight" />

        <ThemePicker />

        <div class="mr-2 lang-wrapper">
            <LangPicker />
        </div>

        <div class="mr-4">
            <AccountMenu />
            <!-- <platon-link link="/system/logout" class="topbar-btn primary-text text-decoration-none">
                <i class="fa fa-sign-out-alt mr-1" />
                {{ $l("platon.logout", "Чиқиш") }}
            </platon-link> -->
        </div>
        <Snowf
            v-if="!isWinterDisabled"
            :amount="25"
            :size="5"
            :speed="0.4"
            :wind="0"
            :opacity="0.8"
            :swing="1"
            :image="null"
            :zIndex="-1"
            :resize="true"
            color="#fff"
        />
    </div>
</template>

<script>
import LangPicker from "@Platon/components/misc/LangPicker.vue"
import ThemePicker from "@Platon/components/misc/ThemePicker.vue"
import WinterMixin from "@Platon/mixins/WinterMixin"
import AccountMenu from "./AccountMenu.vue"
import Snowf from "./Winter.vue"

export default {
    name: "platon-topbar",
    components: { LangPicker, ThemePicker, Snowf, AccountMenu, AccountMenu },
    mixins: [WinterMixin],

    methods: {
        async logout() {
            this.$platonApp.showLogoutDialog()
        }
    },

    computed: {
        topbarRight() {
            return (
                this.$root.$options.components["topbarRight"] || this.$root.$options.components["custom-topbar-right"]
            )
        },

        topbarLeft() {
            return this.$root.$options.components["topbarLeft"] || this.$root.$options.components["custom-topbar-left"]
        }
    }
}
</script>

<style lang="scss" scoped>
.lang-wrapper {
    margin-left: -17px;
}
.topbar-btn {
    background: transparent !important;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: 0.2s;
    padding: 10px;
    display: flex;
    align-items: center;
    height: 40px;

    &:hover {
        background: rgba(0, 0, 0, 0.1) !important;
    }
}
</style>
