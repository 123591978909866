<template>
    <BaseControl v-bind="$attrs">
        <div class="input-group">
            <div class="custom-file">
                <input
                    type="file"
                    :accept="allowedExtensions"
                    @input="onFileSelected($event.target.files[0])"
                    class="custom-file-input"
                    ref="input"
                    :disabled="disabled || readonly"
                />
                <label class="custom-file-label text-truncate">{{ fileName }}</label>
            </div>
            <div class="input-group-append">
                <button
                    class="btn btn-primary"
                    type="button"
                    @click="downloadRemoteFile"
                    v-if="!isUploading && hasUploadedFile"
                >
                    <i class="fa fa-download"></i>
                </button>

                <button
                    class="btn btn-primary"
                    type="button"
                    @click="$refs.input.click()"
                    v-if="!isUploading && !readonly"
                >
                    <i class="fa fa-cloud-upload-alt"></i>
                </button>

                <button
                    class="btn btn-danger"
                    type="button"
                    @click="deleteUploadedFile()"
                    v-if="!isUploading && hasUploadedFile && !readonly"
                >
                    <i class="fa fa-trash-alt"></i>
                </button>

                <button class="btn btn-primary" type="button" v-if="isUploading">
                    <span>{{ uploadProgress }}% </span>
                    <b-spinner variant="light" style="width: 16px; height: 16px" label="Spinning"></b-spinner>
                </button>

                <button class="btn btn-danger" type="button" v-if="isUploading" @click="cancelUpload()">
                    <i class="fa fa-times"></i>
                </button>
            </div>
        </div>
    </BaseControl>
</template>

<script>
import UploadMixin from "@Platon/components/form/controls/UploadMixin"
import BaseControl from "@Platon/components/form/controls/BaseControl.vue"
import { base64ToFile } from "@Platon/core/helpers/FileHelpers"

export default {
    name: "UploadSingle",
    components: { BaseControl },
    mixins: [UploadMixin],

    props: {
        disabled: {},
        readonly: {}
    },

    mounted() {
        if (Array.isArray(this.item.data) && this.item.data.length > 0) {
            this.remoteFile = this.item.data[0]
        }
    },

    methods: {
        /**
         * @param base64
         * @param filename
         * @param type
         */
        async uploadBase64File(base64, filename = "image.jpg", type = "image/jpeg") {
            return this.onFileSelected(base64ToFile(base64, filename, type))
        }
    }
}
</script>

<style></style>
