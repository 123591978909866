import { AxiosInstance } from "axios"
import { BvToast } from "bootstrap-vue"

/*** @type {AxiosInstance}*/
export let $http = null
/*** @type {AxiosInstance}*/
export let $api = null

export function setPlatonHttpClient(client) {
	$http = client
	window.$http = $http
}

export function setPlatonApiClient(client) {
	$api = client
	window.$api = $api
}

/*** @type Function() */
export let getUser

export function setUserFn(fn) {
	getUser = fn
}

/** @type {BvToast} */
export let $rootToast = null

export function setRootToast($toast) {
	$rootToast = $toast
}
