<template>
    <div>
        <Notification
            v-for="(el, index) of alerts.data"
            :key="`${el.description}-${index}`"
            :element="el"
            :close="alerts.onClose"
        />
    </div>
</template>
<script>
import Notification from "@Platon/components/extended/Notification.vue"
export default {
    name: "NotificationContainer",
    props: {
        eventName: String
    },
    components: { Notification },
    data() {
        return {
            alerts: {}
        }
    },

    mounted() {
        this.$parent.$on(this.eventName, this.setAlerts)
    },

    methods: {
        setAlerts(alerts) {
            this.alerts = alerts
        }
    }
}
</script>
