<template>
    <div class="columns-filter">
        <Modal :footer-actions="actions" :canCancel="false">
            <template #title> {{ $l("platon.table.table-column-settings", "Жадвал устунларини созлаш") }} </template>
            <div class="mb-2 p-2 d-flex justify-content-between align-items-center border-bottom-1">
                <b-form-checkbox
                    id="all"
                    :value="true"
                    :unchecked-value="false"
                    :checked="inputVal.all"
                    @input="changeAll"
                    name="all"
                >
                    {{ $l("platon.table.all-columns", "Барча устунлар") }}
                </b-form-checkbox>
                <b-dropdown
                    size="sm"
                    split
                    :text="$l('platon.table.reset-changes', 'Ўзгаришларни тиклаш')"
                    variant="primary"
                    @click="resetChanges"
                >
                    <b-dropdown-item-button @click="resetOrders">{{
                        $l("platon.table.reset-sorting", "Саралашни тиклаш")
                    }}</b-dropdown-item-button>
                    <b-dropdown-item-button @click="resetTitles">{{
                        $l("platon.table.reset-titles", "Сарлавхаларни тиклаш")
                    }}</b-dropdown-item-button>
                </b-dropdown>
            </div>
            <table-filter-columns
                :orders="inputVal.orders"
                :columns="inputVal.columns"
                :disabled-columns="disabledColumns.columns"
                @checkbox-toggler="getChildValue"
                @re-order="reOrder"
                @change-titles="changeTitle"
            />
        </Modal>
    </div>
</template>

<script>
import cloneDeep from "lodash/cloneDeep"
import Modal from "@Platon/components/extended/Modal.vue"

import TableFilterColumns from "./TableFilterColumns.vue"

export default {
    name: "TableFilterColumnsWrapper",

    props: [
        "columns",
        "disabledColumns",
        "orders",
        "originalColumns",
        "changeMainOrders",
        "columnInitialTitles",
        "titles",
        "resolveModal",
        "rejectModal"
    ],
    components: { TableFilterColumns, Modal },

    data() {
        return {
            inputVal: {
                columns: this.columns,
                all: this.disabledColumns.all ?? true,
                orders: this.orders,
                titles: this.titles
            }
        }
    },

    methods: {
        reOrder(childrenOrders, childrenColumns) {
            this.changeMainOrders(childrenOrders)
            this.inputVal.orders = childrenOrders
            this.inputVal.columns = childrenColumns
        },
        getChildValue(childInputVal) {
            this.inputVal.columns = childInputVal
        },
        changeAll(value) {
            this.inputVal.all = value
            this.changeAllColumns(this.inputVal.columns, value)
        },
        changeAllColumns(columns, value) {
            columns.map((column) => {
                column.enabled = value
                if (Array.isArray(column.children) && column.children.length > 0) {
                    this.changeAllColumns(column.children, value)
                }
            })
        },
        resetChanges() {
            // reset all orders and titles
            this.changeMainOrders({})
            this.inputVal.titles = {}
            this.inputVal.orders = {}
            this.inputVal.columns = cloneDeep(this.originalColumns)
        },
        resetOrders() {
            // reset the orders
            this.changeMainOrders({})
            this.inputVal.orders = {}
            this.inputVal.columns = this.transformTitles(cloneDeep(this.originalColumns), this.inputVal.titles)
        },
        resetTitles() {
            // reset the titles
            this.inputVal.titles = {}
            this.inputVal.columns = this.transformTitles(cloneDeep(this.inputVal.columns), this.columnInitialTitles)
        },
        changeTitle(titles) {
            this.inputVal.titles = titles
        },
        transformTitles(columns, titles) {
            return columns.map((column) => {
                if (column.id in titles) {
                    column.title = titles[column.id]
                }

                if (column.children.length > 0) {
                    column.children = this.transformTitles(column.children, titles)
                }

                return column
            })
        }
    },
    computed: {
        actions() {
            return [
                {
                    text: this.$l("platon.save", "Сақлаш"),
                    handler: () => {
                        this.resolveModal(this.inputVal)
                    }
                },
                {
                    text: this.$l("platon.cancel", "Бекор қилиш"),
                    handler: () => {
                        this.rejectModal()
                    }
                }
            ]
        }
    }
}
</script>

<style>
.columns-filter .modal-form-header:hover {
    cursor: move !important;
}
</style>
