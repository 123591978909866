<template>
    <div class="primary-text">
        {{ $l("platon.tech_support", "Техник ёрдам") }}:
        <a :href="CONTACT_TELEGRAM" target="_blank" class="btn primary-link ml-2">
            <div class="d-flex align-items-center">
                <i style="font-size: 22px" class="fab fa-telegram-plane mr-2"></i> {{ CONTACT_PHONE }}
            </div>
        </a>
    </div>
</template>

<script>
import { CONTACT_PHONE, CONTACT_TELEGRAM } from "@Platon/const"
export default {
    name: "TopbarContacts",
    data() {
        return {
            CONTACT_PHONE,
            CONTACT_TELEGRAM
        }
    }
}
</script>

<style scoped>
.primary-link {
    border: 1px solid transparent;
    color: var(--pl-btn-primary-link-text);
    background-color: var(--pl-btn-primary-link);
}
</style>
