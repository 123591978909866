<template>
    <BaseControl :disable-label="true" v-bind="$attrs">
        <component :key="`html-${$attrs['element-id']}`" :items="siblings" :is="componentComposition" />

        <v-style v-if="item.css">
            {{ item.css }}
        </v-style>
    </BaseControl>
</template>

<script>
import BaseControl from "@Platon/components/form/controls/BaseControl.vue"
import FormDataMixin from "@Platon/components/form/FormDataMixin"
import { IS_DEV } from "@Platon/const"
import { runScopeFn } from "@Platon/core/condition"
import KeyValueControl from "@Platon/components/form/controls/KeyValueControl.vue"

export default {
    name: "HtmlControl",

    data() {
        return {
            html: this.item.htmlContent,
            withLabels: false,
            elementClass: "table-form-element"
        }
    },

    props: {
        item: {}
    },

    components: {
        BaseControl
    },

    computed: {
        /**
         * @return {Object<String, PlatonFormElement>}
         */
        siblings() {
            /**
             * @type PlatonFormElement[]
             */
            let siblings = (this.$parent.$parent.children || []).filter((el) => {
                return el.type !== "html" && el.type !== "fieldset"
            })

            siblings.push(...this.item.children)

            let siblingMap = {}

            for (let sibling of siblings) {
                if (sibling.dataField && Number(sibling.id) !== Number(this.$attrs["element-id"]))
                    siblingMap[sibling.dataField] = sibling
            }

            return siblingMap
        },

        content() {
            if (IS_DEV) console.warn("HTML rerender !!!")

            return this.html.replace(/@\[(\w+)]/g, (match, name) => {
                let sibling = this.siblings[name]

                if (sibling) {
                    return `<platon-form-element key="he-${sibling.id}" :disable-label="${!this.withLabels}"
								:value="items.${name}.value" class="${this.elementClass}" element-type="form_elements" :item="items.${name}"/>`
                }

                return match
            })
        },

        componentComposition() {
            let component = {
                template: this.content,

                components: {
                    KeyValueControl
                },

                mixins: [FormDataMixin],

                inject: {
                    form: {
                        default: undefined
                    }
                },

                props: {
                    items: {}
                }
            }

            if (this.item.js) {
                try {
                    component = Object.assign(component, runScopeFn(`return ${this.item.js}`, null, { $this: this }))
                } catch (e) {
                    // can't create component
                    console.warn({
                        text: "Component warn",
                        js: this.item.js,
                        e
                    })
                }
            }

            return component
        }
    }
}
</script>
