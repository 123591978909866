<template>
    <button :class="`ActionButtonView ${className}`" @click="emitClickableAction">
        <slot />
        <div class="ActionButtonView__loader">
            <i class="mdi mdi-loading" v-show="isLoading" />
        </div>
    </button>
</template>

<script>
export default {
    name: "ActionButton",
    props: {
        isLoading: {
            type: Boolean,
            default: false
        },
        className: {
            type: String,
            default: ""
        }
    },
    methods: {
        emitClickableAction() {
            this.$emit("onclick")
        }
    }
}
</script>

<style lang="scss" scoped>
@import "./utils/mixins.scss";

.ActionButtonView {
    border: none;
    color: #fff;
    height: 42px;
    padding: 0 10px;
    @include display-flex;
    border-radius: $df-border-radius;
    background-color: $df-color-primary;

    &__loader {
        margin-left: 0.5rem;
        animation: s2 1s infinite linear;

        & i {
            font-size: $df-size;
            color: #fff;
        }

        @keyframes s2 {
            to {
                transform: rotate(1turn);
            }
        }
    }

    transition: all 0.12s ease-out;
    &:hover {
        background-color: rgba($color: $df-color-primary, $alpha: 0.8);
    }
}
</style>
