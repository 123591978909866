<template>
    <td v-if="column.__isComplexCell" ref="cell" v-bind="getCellAttr()">
        <PlatonLink
            v-if="this.isTextCell && this.column.__hasLink"
            :link="column.link"
            :link-params="record"
            :stop-propagation="true"
        >
            <component :is="buildComplexCellComponent()" v-bind="getComplexCellProps()" />
        </PlatonLink>

        <component v-else :is="buildComplexCellComponent()" v-bind="getComplexCellProps()" />
    </td>

    <td v-else-if="this.isTextCell && this.column.__hasLink" ref="cell" v-bind="getCellAttr()">
        <PlatonLink :link="column.link" :link-params="record" :stop-propagation="true" v-html="getFormattedText()" />
    </td>

    <td v-else ref="cell" v-bind="getCellAttr()" v-html="getFormattedText()" />
</template>

<script>
import PlatonLink from "@Platon/components/extended/PlatonLink.vue"
import { runScopeFn, wrapFnWithParams } from "@Platon/core/condition"
import { capitalize, ifNull } from "@Platon/core/helpers"
import { numberFormat } from "@Platon/core/helpers/numberHelpers"
import ParametrizedString from "@Platon/core/ParametrizedString"

export default {
    name: "TableCell",
    components: { PlatonLink },

    props: {
        /** @type TableColumn */
        column: {},
        record: {},
        text: {},
        td: {}
    },

    inject: {
        // used cell templates, don't remove
        table: {
            default: undefined
        },
        ptable: {
            default: undefined
        }
    },

    mounted() {
        if (this.column.__hasHint) {
            this.$el.addEventListener("mouseenter", this.hover)
        }

        if (this.$attrs.clone) {
            this.$nextTick(this.fixBounds)
            window.addEventListener("resize", this.redraw)
        }
    },

    beforeDestroy() {
        if (this.column.__hasHint) {
            this.mouseLeave()
        }

        if (this.$attrs.clone) {
            window.removeEventListener("resize", this.redraw)
        }
    },

    methods: {
        hover() {
            let tooltipData = this.getTooltip()

            if (tooltipData) {
                this.$el.addEventListener("mouseleave", this.mouseLeave)
                this.$sendEvent("PLATON:TOOLTIP:SHOW", this.$el, tooltipData)
            }
        },

        mouseLeave() {
            this.$el.removeEventListener("mouseleave", this.mouseLeave)
            this.$sendEvent("PLATON:TOOLTIP:HIDE", this.$el)
        },

        getTooltip() {
            if (this.column.hint && this.isTextCell) {
                return {
                    text: ParametrizedString.replace(this.column.hint, this.record),
                    target: this.$el
                }
            } else {
                return null
            }
        },

        redraw() {
            this.$nextTick(this.fixBounds)
        },

        fixBounds() {
            let original = this.ptable.$el.querySelector(
                `td[data-cell-type='${this.$attrs["data-cell-type"]}'][data-cell-id='${this.column.id}-${this.$attrs.rowIndex}']`
            )

            if (!original) return

            let rect = original.getBoundingClientRect()

            this.$refs.cell.style.height = rect.height + "px"
            this.$refs.cell.style.width = rect.width + "px"
        },

        onCellClick() {
            if (!this.isTextCell) {
                return
            }

            this.$parent.eventEmitter.$emit("onCellClick", this.column.dataField, this.record)

            if (this.column.dataField) {
                this.$parent.eventEmitter.$emit("onCell" + capitalize(this.column.dataField) + "Click", this.record)
            }
        },

        buildComplexCell() {
            let text = this.getFormattedText()
            let use = this.isTextCell

            try {
                /** @type Function|?string */
                let renderFn = this.column.cellFormat
                let data = undefined

                let args = {
                    value: text,
                    data: this.record,
                    col: this.column,
                    use: (type) => {
                        if (this.isFooterCell && type === "footer") {
                            use = true
                        } else if (this.isGroupingCell && type === "group") {
                            use = true
                        }
                    },
                    f: this.$f
                }

                if (typeof renderFn === "function") {
                    data = renderFn.call(this, ...Object.values(args))
                } else if (renderFn !== null && typeof renderFn === "object") {
                    data = renderFn
                } else {
                    data = runScopeFn(renderFn, this, args)
                }

                if (use) {
                    if (typeof data === "object") return data

                    return `<div>${ifNull(data, "")}</div>`
                }
            } catch (e) {
                console.warn(e)
            }

            return `<span>${text}</span>`
        },

        buildComplexCellComponent() {
            let component = this.buildComplexCell()

            if (typeof component === "object") {
                return component
            } else {
                return this.getTemplateRenderComponent(component)
            }
        },

        /**
         * @param {string} template
         * @return Object
         */
        getTemplateRenderComponent(template) {
            return {
                template,
                name: "Cell",
                props: ["data", "value", "col"],
                inject: {
                    table: {
                        default: undefined
                    }
                }
            }
        },

        getComplexCellProps() {
            return {
                data: this.record,
                value: this.getFormattedText(),
                col: this.column
            }
        },

        getCellAttr() {
            let align

            if (this.isGroupingCell) {
                align = "text-center"
            } else if (this.isFooterCell) {
                align = `text-${this.column.footerAlign || "left"}`
            } else {
                align = `text-${this.column.dataAlign || "left"}`
            }

            const cls = [align]

            if (this.isGroupingCell) {
                cls.push("group-cell")
            }

            let styles = {}

            if (this.isTextCell) {
                const { formatCondition, formatColor, formatBackgroundColor } = this.column
                if (!!formatCondition === false || wrapFnWithParams(formatCondition).call(this.record)) {
                    styles["color"] = formatColor
                    styles["backgroundColor"] = formatBackgroundColor
                }
            }

            return {
                class: cls,
                style: styles
            }
        },

        getFormattedText() {
            // Don't format text for grouping cells
            if (this.isGroupingCell) {
                return this.text
            }

            if (Number.isInteger(this.column.numberFormat)) {
                return ifNull(numberFormat(this.text, this.column.numberFormat), "")
            } else {
                return ifNull(this.text, "")
            }
        }
    },

    computed: {
        isGroupingCell() {
            return this.record.__rowType === "header"
        },

        isFooterCell() {
            return this.record.__rowType === "footer"
        },

        isTextCell() {
            return !this.isGroupingCell && !this.isFooterCell
        }
    }
}
</script>
