/**
 * @typedef {object} PlatonForm
 * @property {number} id
 * @property {number} projectId
 * @property {string} js
 * @property {string} css
 * @property {string} name
 * @property {string} addTitle
 * @property {string} editTitle
 * @property {string} addHeader
 * @property {string} editHeader
 * @property {boolean} canCreate
 * @property {boolean} canEdit
 * @property {boolean} canDelete
 * @property {boolean} canRestore
 * @property {boolean} canClone
 * @property {boolean} canHardDelete
 * @property {number} gridOffset
 * @property {string} focusedFieldName
 * @property {string} nextLink
 * @property {string} validationScript
 * @property {Object} params
 * @property {Object.<string, string>} attributes
 * @property {PlatonFormFieldset[]} elements
 * @property {object} formData
 */

/**
 * @typedef {object} PlatonFormFieldset
 * @property {PlatonFormElement[]} children
 * @property {number} projectId
 * @property {string} type
 * @property {?string} width
 * @property {?string} cssStyle
 * @property {?string} displayCondition
 * @property {?string} readOnly
 * @property {?string} onChange
 */

/**
 * @typedef {object} PlatonFormElement
 * @property {number} id
 * @property {number} projectId
 * @property {string} dataField
 * @property {string} defaultValue
 * @property {string} value
 * @property {Object.<string, string>} attributes
 * @property {string} type
 * @property {?string} width
 * @property {?string} cssStyle
 * @property {?string|?function} displayCondition
 * @property {?function} customValidator
 * @property {boolean} isReadOnly
 * @property {boolean} isDisabled
 * @property {boolean} isRequired
 * @property {?string} onChange
 * @property {?string} minDateTime
 * @property {?string} maxDateTime
 * @property {?string} format
 * @property {?string} mask
 * @property {?string} textAlign
 * @property {?string} placeholder
 * @property {?string} htmlContent
 * @property {?string} buttonIcon
 * @property {?string} buttonText
 * @property {?boolean} isMultiple
 * @property {?string} allowedExtensions
 * @property {?number} minCount
 * @property {?number} maxCount
 * @property {?number} maxSize
 * @property {?string} mapCenter
 * @property {?number} mapZoom
 * @property {?string} component
 * @property {?boolean} isRelatedSelect
 * @property {?string} relatedSelect
 * @property {?string} filterBy
 * @property {?boolean} isMultiSelect
 * @property {?string} tabsPosition
 **/

/**
 * @typedef ContextMenuItem
 * @property {string} icon
 * @property {string} text
 * @property {Function} handler
 */

/**
 * @typedef PlatonFile
 * @property {string} contentType
 * @property {string} createdAt
 * @property {string} extension
 * @property {string} id
 * @property {string} name
 * @property {number} size
 */
export class PlatonFile {}

export const PlatonFileProp = [PlatonFile, Object]
